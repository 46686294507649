import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { purple } from "@mui/material/colors";
import Header from "./Header/Header";
import Footer from "./footer/Footer";
import { useSelector } from "react-redux";

const theme = createTheme({
      palette: {
            primary: {
                  // Purple and green play nicely together.
                  main: purple[500],
            },
            secondary: {
                  // This is green.A700 as hex.
                  main: "#11cb5f",
            },
      },
});

const ComprobantePago = () => {
      const [contrato, setContrato] = useState([]);
      const [pagos, setPagos] = useState([]);
      const [count, setCount] = useState(0);
      const { numero } = useParams();
      const agencia = useSelector((state) => state.agencia.selectedAgencia);
      theme.typography.h5 = {
            fontSize: "1.6rem",
            "@media (max-width:600px)": {
                  fontSize: "0.6rem",
            },
      };

      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.5rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      useEffect(() => {
            traerContrato();
            traerPagos();
      }, []);

      const traerContrato = () => {
            fetch(
                  `https://dejandohuellas.syfacol.com/pagos/obtenerContrato/${numero}`
            )
                  .then((res) => res.json())
                  .then((res) => setContrato(res));
      };

      const traerPagos = () => {
            fetch(
                  `https://dejandohuellas.syfacol.com/pagos/pagoReciente/${numero}`
            )
                  .then((res) => res.json())
                  .then((res) => setPagos(res));
      };

      const generarPDF = () => {
            printDocument();
            setTimeout(() => {
                  window.location = "/cobros";
            }, 3000);
      };

      const printDocument = () => {
            const pdf = new jsPDF("p", "mm", "a4");
            const input = document.getElementById("content");
            html2canvas(input).then((canvas) => {
                  let imgWidth = 208;
                  let imgHeight = (canvas.height * imgWidth) / canvas.width;
                  const imgData = canvas.toDataURL("image/jpeg", 1.0);
                  pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

                  pdf.save(`pago_${numero}.pdf`);
            });
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const numeroALetras = (() => {
            function Unidades(num) {
                  switch (num) {
                        case 1:
                              return "UN";
                        case 2:
                              return "DOS";
                        case 3:
                              return "TRES";
                        case 4:
                              return "CUATRO";
                        case 5:
                              return "CINCO";
                        case 6:
                              return "SEIS";
                        case 7:
                              return "SIETE";
                        case 8:
                              return "OCHO";
                        case 9:
                              return "NUEVE";
                  }

                  return "";
            } //Unidades()

            function Decenas(num) {
                  let decena = Math.floor(num / 10);
                  let unidad = num - decena * 10;

                  switch (decena) {
                        case 1:
                              switch (unidad) {
                                    case 0:
                                          return "DIEZ";
                                    case 1:
                                          return "ONCE";
                                    case 2:
                                          return "DOCE";
                                    case 3:
                                          return "TRECE";
                                    case 4:
                                          return "CATORCE";
                                    case 5:
                                          return "QUINCE";
                                    default:
                                          return "DIECI" + Unidades(unidad);
                              }
                        case 2:
                              switch (unidad) {
                                    case 0:
                                          return "VEINTE";
                                    default:
                                          return "VEINTI" + Unidades(unidad);
                              }
                        case 3:
                              return DecenasY("TREINTA", unidad);
                        case 4:
                              return DecenasY("CUARENTA", unidad);
                        case 5:
                              return DecenasY("CINCUENTA", unidad);
                        case 6:
                              return DecenasY("SESENTA", unidad);
                        case 7:
                              return DecenasY("SETENTA", unidad);
                        case 8:
                              return DecenasY("OCHENTA", unidad);
                        case 9:
                              return DecenasY("NOVENTA", unidad);
                        case 0:
                              return Unidades(unidad);
                  }
            } //Unidades()

            function DecenasY(strSin, numUnidades) {
                  if (numUnidades > 0)
                        return strSin + " Y " + Unidades(numUnidades);

                  return strSin;
            } //DecenasY()

            function Centenas(num) {
                  let centenas = Math.floor(num / 100);
                  let decenas = num - centenas * 100;

                  switch (centenas) {
                        case 1:
                              if (decenas > 0)
                                    return "CIENTO " + Decenas(decenas);
                              return "CIEN";
                        case 2:
                              return "DOSCIENTOS " + Decenas(decenas);
                        case 3:
                              return "TRESCIENTOS " + Decenas(decenas);
                        case 4:
                              return "CUATROCIENTOS " + Decenas(decenas);
                        case 5:
                              return "QUINIENTOS " + Decenas(decenas);
                        case 6:
                              return "SEISCIENTOS " + Decenas(decenas);
                        case 7:
                              return "SETECIENTOS " + Decenas(decenas);
                        case 8:
                              return "OCHOCIENTOS " + Decenas(decenas);
                        case 9:
                              return "NOVECIENTOS " + Decenas(decenas);
                  }

                  return Decenas(decenas);
            } //Centenas()

            function Seccion(num, divisor, strSingular, strPlural) {
                  let cientos = Math.floor(num / divisor);
                  let resto = num - cientos * divisor;

                  let letras = "";

                  if (cientos > 0)
                        if (cientos > 1)
                              letras = Centenas(cientos) + " " + strPlural;
                        else letras = strSingular;

                  if (resto > 0) letras += "";

                  return letras;
            } //Seccion()

            function Miles(num) {
                  let divisor = 1000;
                  let cientos = Math.floor(num / divisor);
                  let resto = num - cientos * divisor;

                  let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
                  let strCentenas = Centenas(resto);

                  if (strMiles == "") return strCentenas;

                  return strMiles + " " + strCentenas;
            } //Miles()

            function Millones(num) {
                  let divisor = 1000000;
                  let cientos = Math.floor(num / divisor);
                  let resto = num - cientos * divisor;

                  let strMillones = Seccion(
                        num,
                        divisor,
                        "UN MILLON DE",
                        "MILLONES DE"
                  );
                  let strMiles = Miles(resto);

                  if (strMillones == "") return strMiles;

                  return strMillones + " " + strMiles;
            } //Millones()

            return function NumeroALetras(num, currency) {
                  currency = currency || {};
                  let data = {
                        numero: num,
                        enteros: Math.floor(num),
                        centavos: Math.round(num * 100) - Math.floor(num) * 100,
                        letrasCentavos: "",
                        letrasMonedaPlural: currency.plural || "PESOS", //'PESOS', 'Dólares', 'Bolívares', 'etcs'
                        letrasMonedaSingular: currency.singular || "PESO", //'PESO', 'Dólar', 'Bolivar', 'etc'
                        letrasMonedaCentavoPlural:
                              currency.centPlural || "PESOS",
                        letrasMonedaCentavoSingular:
                              currency.centSingular || "PESO",
                  };

                  if (data.centavos > 0) {
                        data.letrasCentavos =
                              "CON " +
                              (function () {
                                    if (data.centavos == 1)
                                          return (
                                                Millones(data.centavos) +
                                                " " +
                                                data.letrasMonedaCentavoSingular
                                          );
                                    else
                                          return (
                                                Millones(data.centavos) +
                                                " " +
                                                data.letrasMonedaCentavoPlural
                                          );
                              })();
                  }

                  if (data.enteros == 0)
                        return (
                              "CERO " +
                              data.letrasMonedaPlural +
                              " " +
                              data.letrasCentavos
                        );
                  if (data.enteros == 1)
                        return (
                              Millones(data.enteros) +
                              " " +
                              data.letrasMonedaSingular +
                              " " +
                              data.letrasCentavos
                        );
                  else
                        return (
                              Millones(data.enteros) +
                              " " +
                              data.letrasMonedaPlural +
                              " " +
                              data.letrasCentavos
                        );
            };
      })();

      return (
            <>
                  <Header />
                  <ThemeProvider theme={theme}>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              alignItems="center"
                              style={{ marginTop: "70px" }}
                        >
                              <div
                                    style={{
                                          marginTop: 15,
                                    }}
                              >
                                    <ThemeProvider theme={theme}>
                                          <Button
                                                onClick={() => generarPDF()}
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                }}
                                                variant="contained"
                                          >
                                                Generar Recibo
                                          </Button>
                                    </ThemeProvider>
                              </div>
                        </Grid>
                        <div
                              id="content"
                              style={{ paddingTop: 50, marginBottom: "20px" }}
                        >
                              <Container>
                                    {pagos.map((p) => (
                                          <Grid
                                                container
                                                justifyContent="center"
                                                md={12}
                                                alignItems="center"
                                          >
                                                <Grid
                                                      container
                                                      justifyContent="space-between"
                                                      md={11}
                                                      sm={12}
                                                >
                                                      <Grid
                                                            item
                                                            xs={6}
                                                            sm={3}
                                                            md={4}
                                                      >
                                                            <img
                                                                  src={
                                                                        process
                                                                              .env
                                                                              .PUBLIC_URL +
                                                                        "/images/logoEmpresa.png"
                                                                  }
                                                                  width="100%"
                                                            />
                                                            <div
                                                                  style={{
                                                                        width: "100%",
                                                                  }}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              NIT
                                                                              :
                                                                              901365548
                                                                              -
                                                                              9
                                                                        </b>
                                                                  </Typography>
                                                            </div>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={3}
                                                            sm={3}
                                                            md={3}
                                                      >
                                                            <Typography
                                                                  variant="subtitle1"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        RECIBO
                                                                        DE CAJA
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h5"
                                                                  style={{
                                                                        color: "red",
                                                                  }}
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        N°{" "}
                                                                        {p.Id}
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="subtitle1"
                                                                  component="h2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Fecha:{" "}
                                                                        {
                                                                              p.Fecha
                                                                        }
                                                                  </b>
                                                                  <hr></hr>
                                                            </Typography>
                                                      </Grid>
                                                </Grid>
                                                {contrato.map((c) => (
                                                      <>
                                                            {" "}
                                                            <Grid
                                                                  container
                                                                  spacing={2}
                                                                  justifyContent="center"
                                                                  style={{
                                                                        marginTop:
                                                                              "10px",
                                                                  }}
                                                                  md={11}
                                                                  sm={12}
                                                            >
                                                                  <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Recibimos
                                                                                    de:
                                                                              </b>{" "}
                                                                              {
                                                                                    c.Nombres
                                                                              }
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={4}
                                                                        sm={4}
                                                                        md={4}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    NIT
                                                                                    y/o
                                                                                    CC.
                                                                              </b>{" "}
                                                                              {
                                                                                    c.Cedula
                                                                              }
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={2}
                                                                        sm={2}
                                                                        md={2}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Contrato
                                                                                    No.
                                                                              </b>{" "}
                                                                              <b>
                                                                                    {
                                                                                          c.Codigo
                                                                                    }
                                                                              </b>
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={8}
                                                                        sm={8}
                                                                        md={8}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Dirección:
                                                                              </b>{" "}
                                                                              {c.Barrio +
                                                                                    " " +
                                                                                    c.Dir_Cliente}
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={4}
                                                                        sm={4}
                                                                        md={4}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Teléfono:
                                                                              </b>{" "}
                                                                              {
                                                                                    c.Tel_Cliente
                                                                              }
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={8}
                                                                        sm={8}
                                                                        md={8}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    La
                                                                                    suma
                                                                                    de:
                                                                              </b>{" "}
                                                                              {numeroALetras(
                                                                                    p.Valor
                                                                              )}
                                                                        </Typography>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={4}
                                                                        sm={4}
                                                                        md={4}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              component="h2"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    {formatterPeso.format(
                                                                                          p.Valor
                                                                                    )}
                                                                              </b>
                                                                        </Typography>
                                                                  </Grid>
                                                            </Grid>
                                                            <Grid
                                                                  container
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  md={11}
                                                                  sm={12}
                                                            >
                                                                  <table className="table table-bordered table-sm ">
                                                                        <thead>
                                                                              <tr
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "#ff0000",
                                                                                    }}
                                                                              >
                                                                                    <th colSpan="1">
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      CODIGO
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th colSpan="3">
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      DETALLE
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th colSpan="1">
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      VALOR
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                              <tr>
                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      {
                                                                                                            p.Id
                                                                                                      }
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="3"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                {
                                                                                                      p.Detalle
                                                                                                }
                                                                                          </Typography>
                                                                                    </th>

                                                                                    <th
                                                                                          colSpan="1"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle1"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      {formatterPeso.format(
                                                                                                            p.Valor
                                                                                                      )}
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                        </tbody>
                                                                  </table>
                                                            </Grid>
                                                            <Grid
                                                                  container
                                                                  mt={1}
                                                                  md={11}
                                                                  sm={12}
                                                                  xs={12}
                                                            >
                                                                  <Grid
                                                                        item
                                                                        xs={4}
                                                                        sm={4}
                                                                        md={4}
                                                                  >
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Cheque
                                                                                    No.
                                                                              </b>
                                                                        </Typography>
                                                                        <div
                                                                              style={{
                                                                                    width: "100%",
                                                                                    height: "30px",
                                                                                    display: "inline-block",
                                                                              }}
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    align="start"
                                                                              >
                                                                                    <b>
                                                                                          {" "}
                                                                                          {
                                                                                                p.Id
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                              <hr
                                                                                    style={{
                                                                                          background:
                                                                                                "black",
                                                                                          marginBottom: 0,
                                                                                          marginTop: 0,
                                                                                    }}
                                                                              ></hr>
                                                                        </div>

                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Valor
                                                                                    Cuota:
                                                                              </b>{" "}
                                                                        </Typography>

                                                                        <div
                                                                              style={{
                                                                                    width: "100%",
                                                                                    height: "30px",
                                                                                    display: "inline-block",
                                                                              }}
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h4"
                                                                                    align="start"
                                                                              >
                                                                                    <b>
                                                                                          {formatterPeso.format(
                                                                                                p.Valor
                                                                                          )}
                                                                                    </b>
                                                                              </Typography>
                                                                              <hr
                                                                                    style={{
                                                                                          background:
                                                                                                "black",
                                                                                          marginBottom: 0,
                                                                                          marginTop: 0,
                                                                                    }}
                                                                              ></hr>
                                                                        </div>
                                                                        <Typography
                                                                              variant="subtitle1"
                                                                              align="start"
                                                                        >
                                                                              <b>
                                                                                    Medio
                                                                                    de
                                                                                    Pago:
                                                                              </b>
                                                                        </Typography>
                                                                        <div
                                                                              style={{
                                                                                    width: "100%",
                                                                                    height: "30px",
                                                                                    display: "inline-block",
                                                                              }}
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h4"
                                                                                    align="start"
                                                                              >
                                                                                    <b>
                                                                                          {
                                                                                                p.MedioPago
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                              <hr
                                                                                    style={{
                                                                                          background:
                                                                                                "black",
                                                                                          marginBottom: 0,
                                                                                          marginTop: 0,
                                                                                    }}
                                                                              ></hr>
                                                                        </div>
                                                                  </Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={2}
                                                                        sm={2}
                                                                        md={2}
                                                                  ></Grid>
                                                                  <Grid
                                                                        item
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={6}
                                                                  >
                                                                        <table className="table table-bordered table-sm  ">
                                                                              <thead>
                                                                                    <tr>
                                                                                          <th colSpan="1">
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                >
                                                                                                      <b>
                                                                                                            Elaborado
                                                                                                            por:
                                                                                                      </b>
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th colSpan="3">
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                >
                                                                                                      <b>
                                                                                                            Aprobado
                                                                                                            por:
                                                                                                      </b>
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th colSpan="1">
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                >
                                                                                                      <b>
                                                                                                            Contabilizado
                                                                                                            por:
                                                                                                      </b>
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                              </thead>

                                                                              <tbody>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                >
                                                                                                      {
                                                                                                            p.ElaboradoPor
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                ></Typography>
                                                                                          </th>

                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                      align="center"
                                                                                                ></Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                              </tbody>
                                                                        </table>
                                                                  </Grid>
                                                            </Grid>
                                                            <Grid
                                                                  container
                                                                  justifyContent="center"
                                                                  mt={1}
                                                                  md={11}
                                                                  sm={12}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h4"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              Realice
                                                                              su
                                                                              pago
                                                                              electrónico
                                                                              en
                                                                              Daviplata
                                                                              y
                                                                              Nequi
                                                                              al
                                                                              3128811225
                                                                              y
                                                                              Ahorro
                                                                              a
                                                                              la
                                                                              mano
                                                                              al
                                                                              03123881225
                                                                        </b>
                                                                  </Typography>
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h4"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              Oficina:{" "}
                                                                              {
                                                                                    c.Dir_Agencia
                                                                              }{" "}
                                                                              {
                                                                                    c.Tel_Agencia
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>
                                                            {agencia === 1 ? (
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h4"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              Parque
                                                                              Cementerio:
                                                                              Km
                                                                              12
                                                                              Tres
                                                                              esquinas,
                                                                              Vía
                                                                              Florencia
                                                                              a
                                                                              Morelia
                                                                              Caquetá
                                                                        </b>
                                                                  </Typography>
                                                            ) : null}
                                                      </>
                                                ))}
                                          </Grid>
                                    ))}
                              </Container>
                        </div>
                  </ThemeProvider>
                  <Footer />
            </>
      );
};
export default ComprobantePago;
