import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";
import Popover from "@mui/material/Popover";
import * as Yup from "yup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";
import { useSelector } from "react-redux";

const CampoE = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [openModal, setOpenModal] = useState(false);
      const [mascotas, setMascotas] = useState([]);
      const [fallecidos, setFallecidos] = useState([]);
      const [data, setData] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [posicion, setPosicion] = useState("");
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );
      const fecha = new Date();
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate() +
            " " +
            fecha.getHours() +
            ":" +
            fecha.getMinutes() +
            ":" +
            fecha.getSeconds();
      const url = process.env.PUBLIC_URL + "/images/lotemapacementerio.png";

      const [anchorEl, setAnchorEl] = useState(null);

      const theme = createTheme();

      theme.typography.h6 = {
            fontSize: "1.4rem",
            "@media (max-width:600px)": {
                  fontSize: "0.6rem",
            },
      };

      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.4rem",
            },
      };

      const handlePopoverOpen = (muerto, e) => {
            setData(muerto);
            setAnchorEl(e.currentTarget);
      };

      const handlePopoverClose = () => {
            setData("");
            setAnchorEl(null);
      };

      const open = Boolean(anchorEl);

      const cajas = [
            {
                  Posicion: "E_101",
            },
            {
                  Posicion: "E_102",
            },

            {
                  Posicion: "E_103",
            },

            {
                  Posicion: "E_104",
            },

            {
                  Posicion: "E_105",
            },

            {
                  Posicion: "E_106",
            },

            {
                  Posicion: "E_107",
            },

            {
                  Posicion: "E_108",
            },

            {
                  Posicion: "E_109",
            },

            {
                  Posicion: "E_110",
            },
            {
                  Posicion: "E_111",
            },

            {
                  Posicion: "E_112",
            },
            {
                  Posicion: "E_113",
            },
            {
                  Posicion: "E_114",
            },
            {
                  Posicion: "E_115",
            },
            {
                  Posicion: "E_116",
            },
            {
                  Posicion: "E_117",
            },
            {
                  Posicion: "E_118",
            },
            {
                  Posicion: "E_119",
            },
            {
                  Posicion: "E_120",
            },
            {
                  Posicion: "E_121",
            },
            {
                  Posicion: "E_122",
            },
            {
                  Posicion: "E_123",
            },
            {
                  Posicion: "E_124",
            },
            {
                  Posicion: "E_125",
            },
      ];

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerMacotasFallecidas();
                  traerMacotas();
                  setActualizar(false);
            }
      }, [actualizar]);

      const traerMacotas = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/todasMascotas"
            )
                  .then((res) => res.json())
                  .then((res) => setMascotas(res));
      };

      const traerMacotasFallecidas = () => {
            fetch(
                  `https://dejandohuellas.syfacol.com/fallecidos/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        recorrerArray(res);
                  });
      };
      //SI EL LA POSICION  DE LA MASCOTA ES === A AL ID DEL DIV ENTONCES CAMBIAR DE COLOR

      const recorrerArray = (res) => {
            cajas.map((r) => {
                  let muertos = res.filter(
                        (fallecido) => fallecido.Posicion === r.Posicion
                  );
                  muertos.map(
                        (m) => (
                              (r.Id = m.Id),
                              (r.Fecha = m.Fecha),
                              (r.Nombre = m.Nombre),
                              (r.Tipo = m.Tipo),
                              (r.Raza = m.Raza),
                              (r.Sexo = m.Sexo),
                              (r.Plan = m.Plan),
                              (r.Edad = m.Edad),
                              (r.IdPropietario = m.IdPropietario),
                              (r.Nombres = m.Nombres)
                        )
                  );
            });
            console.log(cajas);
            setFallecidos(cajas);
      };

      const cambiarEstado = (id) => {
            const obj = {
                  Estado: false,
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/mascotasEstado/${id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const guardarMascotaFallecida = (values) => {
            cambiarEstado(values.Id);
            const obj = {
                  Fecha: fechaActual,
                  IdMascota: values.Id,
                  Posicion: posicion,
                  IdAgencia: selectedAgencia,
            };
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/fallecidos/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 1500,
                        })
                  );

            setActualizar(!actualizar);
            setOpenModal(!openModal);
      };

      const cerrarModal = () => {
            setPosicion("");
            setOpenModal(!openModal);
      };

      const abrirModal = (muerto) => {
            if (Object.keys(muerto).length === 1) {
                  setPosicion(muerto.Posicion);
                  setOpenModal(!openModal);
            } else {
                  Swal.fire({
                        position: "center",
                        icon: "info",
                        title:
                              "El campo " +
                              muerto.Posicion +
                              " ya esta ocupado!",
                        showConfirmButton: false,
                        timer: 2000,
                  });
            }
      };

      return (
            <>
                  <Header />
                  <Container>
                        <ThemeProvider theme={theme}>
                              <div style={{ background: "red" }}>
                                    <Popover
                                          id="mouse-over-popover"
                                          sx={{
                                                pointerEvents: "none",
                                          }}
                                          open={open}
                                          anchorEl={anchorEl}
                                          anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                          }}
                                          transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                          }}
                                          onClose={handlePopoverClose}
                                          disableRestoreFocus
                                    >
                                          <Typography
                                                align="center"
                                                variant="subtitle1"
                                                sx={{ p: 1 }}
                                          >
                                                <b>Información</b>
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>
                                                      UBICACIÓN: {data.Posicion}
                                                </b>
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>NOMBRE: </b>
                                                {data.Nombre}
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>MURIO: </b>
                                                {data.Fecha}
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>TIPO: </b>
                                                {data.Tipo}
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>SEXO: </b>
                                                {data.Sexo}
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>EDAD: </b>
                                                {data.Edad}
                                          </Typography>
                                          <Typography
                                                variant="subtitle2"
                                                sx={{ pl: 1, pr: 1 }}
                                          >
                                                <b>PLAN: </b>
                                                {data.Plan}
                                          </Typography>
                                    </Popover>
                              </div>
                              <Dialog open={openModal} onClose={openModal}>
                                    <div
                                          style={{
                                                padding: {
                                                      sx: "5px",
                                                      sm: "10px",
                                                      md: "20px",
                                                },
                                          }}
                                    >
                                          <Formik
                                                initialValues={{
                                                      Id: "",
                                                }}
                                                validationSchema={Yup.object().shape(
                                                      {
                                                            Id: Yup.string().required(
                                                                  "* Ingrese una mascota!"
                                                            ),
                                                      }
                                                )}
                                                onSubmit={(
                                                      values,
                                                      { resetForm }
                                                ) => {
                                                      guardarMascotaFallecida(
                                                            values
                                                      );
                                                      //resetForm();
                                                }}
                                          >
                                                {({
                                                      errors,
                                                      touched,
                                                      values,
                                                      setFieldValue,
                                                      handleBlur,
                                                      handleChange,
                                                }) => (
                                                      <Form>
                                                            <DialogTitle>
                                                                  <Typography
                                                                        variant="h5"
                                                                        component="h3"
                                                                        align="center"
                                                                        style={{
                                                                              marginBottom:
                                                                                    "-10px",
                                                                        }}
                                                                  >
                                                                        <b>
                                                                              Mascota
                                                                              Fallecida
                                                                        </b>
                                                                  </Typography>
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                  <DialogContentText
                                                                        style={{
                                                                              marginBottom:
                                                                                    "15px",
                                                                        }}
                                                                  >
                                                                        Si la
                                                                        mascota
                                                                        ha
                                                                        muerto
                                                                        aquí la
                                                                        puedes
                                                                        buscar y
                                                                        dar
                                                                        click en
                                                                        murio, o
                                                                        si el
                                                                        cliente
                                                                        es
                                                                        particular
                                                                        puedes
                                                                        ir a
                                                                        registrarlo...
                                                                  </DialogContentText>

                                                                  <Autocomplete
                                                                        id="Id"
                                                                        name="Id"
                                                                        size="small"
                                                                        options={
                                                                              mascotas
                                                                        }
                                                                        getOptionLabel={(
                                                                              option
                                                                        ) =>
                                                                              option.Nombre +
                                                                              " - " +
                                                                              option.IdPropietario
                                                                        }
                                                                        onChange={(
                                                                              e,
                                                                              value
                                                                        ) => {
                                                                              console.log(
                                                                                    value
                                                                              );
                                                                              setFieldValue(
                                                                                    "Id",
                                                                                    value.Id !==
                                                                                          null
                                                                                          ? value.Id
                                                                                          : values.Id
                                                                              );
                                                                        }}
                                                                        renderInput={(
                                                                              params
                                                                        ) => (
                                                                              <TextField
                                                                                    error={Boolean(
                                                                                          touched.Id &&
                                                                                                errors.Id
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.Id &&
                                                                                          errors.Id
                                                                                    }
                                                                                    label="Buscar Mascota ..."
                                                                                    fullWidth
                                                                                    name="Id"
                                                                                    {...params}
                                                                              />
                                                                        )}
                                                                  />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                  <Grid
                                                                        style={{
                                                                              marginTop:
                                                                                    "8px",
                                                                              marginBottom:
                                                                                    "8px",
                                                                        }}
                                                                        container
                                                                        md={12}
                                                                        justifyContent="center"
                                                                  >
                                                                        <Button
                                                                              variant="contained"
                                                                              size="small"
                                                                              type="submit"
                                                                              style={{
                                                                                    marginRight:
                                                                                          "10px",
                                                                                    color: "white",
                                                                                    background:
                                                                                          "#800000",
                                                                              }}
                                                                        >
                                                                              MURIO
                                                                        </Button>
                                                                        <Button
                                                                              style={{
                                                                                    marginRight:
                                                                                          "10px",
                                                                                    color: "white",
                                                                                    background:
                                                                                          "#800000",
                                                                              }}
                                                                              size="small"
                                                                              type="button"
                                                                              variant="contained"
                                                                              onClick={() =>
                                                                                    (window.location =
                                                                                          "/servicioparticular")
                                                                              }
                                                                        >
                                                                              Particular
                                                                        </Button>
                                                                        <Button
                                                                              style={{
                                                                                    color: "white",
                                                                                    background:
                                                                                          "#800000",
                                                                              }}
                                                                              size="small"
                                                                              type="button"
                                                                              variant="contained"
                                                                              onClick={() =>
                                                                                    cerrarModal()
                                                                              }
                                                                        >
                                                                              Cancelar
                                                                        </Button>
                                                                  </Grid>
                                                            </DialogActions>
                                                      </Form>
                                                )}
                                          </Formik>
                                    </div>
                              </Dialog>

                              <Grid
                                    container
                                    xm={12}
                                    sm={12}
                                    md={12}
                                    justifyContent="center"
                                    style={{ marginTop: "70px" }}
                              >
                                    <Grid
                                          container
                                          sm={10}
                                          xs={10}
                                          md={10}
                                          justifyContent="center"
                                          sx={{ mb: { xs: 1, sm: 1, md: 1 } }}
                                    >
                                          <Typography
                                                variant="h5"
                                                align="center"
                                                style={{ marginTop: "12px" }}
                                          >
                                                <b>CAMPO E</b>
                                          </Typography>
                                    </Grid>
                              </Grid>
                              <Grid
                                    container
                                    justifyContent="center"
                                    direction="row-reverse"
                                    sm={12}
                                    xs={12}
                                    md={12}
                              >
                                    {fallecidos.map((f) => (
                                          <Grid
                                                onClick={() => abrirModal(f)}
                                                id={f.Posicion}
                                                item
                                                xs={2}
                                                sm={2}
                                                md={2}
                                                style={{
                                                      paddingTop: "0px",
                                                      paddingLeft: "0px",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                }}
                                                sx={{
                                                      cursor: "pointer",
                                                      webkitBoxShadow:
                                                            "1px 3px 1px #9E9E9E",
                                                      mozBoxShadow:
                                                            "1px 2px 1px #9E9E9E",
                                                      boxShadow:
                                                            "1px 4px 1px red",
                                                      border: "2px solid red",
                                                      m: {
                                                            xs: 0.5,
                                                            sm: 1,
                                                            md: 2,
                                                      },
                                                      borderRadius: 1,
                                                }}
                                          >
                                                {(Object.keys(f).length > 1 && (
                                                      <ImageListItem
                                                            style={{
                                                                  width: "100%",
                                                            }}
                                                      >
                                                            <img
                                                                  src={`${url}?w=248&fit=crop&auto=format`}
                                                                  srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                  loading="lazy"
                                                                  width="100%"
                                                                  sx={{
                                                                        borderRadius: 2,
                                                                  }}
                                                            />
                                                            <ImageListItemBar
                                                                  sx={{
                                                                        background:
                                                                              "rgba(0,0,0,0.0)",

                                                                        "& .MuiImageListItemBar-titleWrap":
                                                                              {
                                                                                    padding: {
                                                                                          xs: "1px 1px",
                                                                                          sm: "3px 3px",
                                                                                          md: "9px 9px",
                                                                                    },
                                                                              },
                                                                  }}
                                                                  aria-owns={
                                                                        open
                                                                              ? "mouse-over-popover"
                                                                              : undefined
                                                                  }
                                                                  aria-haspopup="true"
                                                                  onMouseEnter={(
                                                                        e
                                                                  ) =>
                                                                        handlePopoverOpen(
                                                                              f,
                                                                              e
                                                                        )
                                                                  }
                                                                  onMouseLeave={
                                                                        handlePopoverClose
                                                                  }
                                                                  title={
                                                                        <Typography
                                                                              align="start"
                                                                              variant="subtitle1"
                                                                        >
                                                                              <b>
                                                                                    {
                                                                                          f.Nombre
                                                                                    }
                                                                              </b>
                                                                        </Typography>
                                                                  }
                                                                  subtitle={
                                                                        <Typography
                                                                              align="start"
                                                                              variant="subtitle2"
                                                                        >
                                                                              <b>
                                                                                    {
                                                                                          f.Fecha
                                                                                    }
                                                                              </b>
                                                                        </Typography>
                                                                  }
                                                            />
                                                      </ImageListItem>
                                                )) || (
                                                      <>
                                                            <Typography
                                                                  variant="h6"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        Disponible{" "}
                                                                  </b>
                                                                  <br></br>
                                                                  <b>
                                                                        {
                                                                              f.Posicion
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                      </>
                                                )}
                                          </Grid>
                                    ))}
                              </Grid>
                        </ThemeProvider>
                  </Container>
                  <Footer />
            </>
      );
};

export default CampoE;
