import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataGrid /*esES*/ } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import DateFnsUtils from "@date-io/date-fns";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import { Container, Grid, Typography, Button, Paper } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatIcon from "@material-ui/icons/Chat";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AddCommentIcon from "@material-ui/icons/AddComment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import HistoryIcon from "@mui/icons-material/History";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";
import { useSelector } from "react-redux";

const StyledBadge = withStyles((theme) => ({
      badge: {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: "0 4px",
      },
}))(Badge);

const fecha = new Date();

const CobrosHoy = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [titulo, setTitulo] = useState("para Hoy");
      const [visible, setVisible] = useState(true);
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate();
      const [cobros, setCobros] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const [openCobros, setOpenCobros] = useState(false);
      const [openComentarios, setOpenComentarios] = useState(false);
      const [openVerComentarios, setOpenVerComentarios] = useState(false);
      const [data, setData] = useState([]);
      const [coments, setComents] = useState([]);
      const [comentarios, setComentarios] = useState([]);
      const [openAgendar, setOpenAgendar] = useState(false);
      const [openPago, setOpenPago] = useState(false);
      const [pagados, setPagados] = useState([]);
      const [pendientes, setPendientes] = useState([]);
      const [cobrar, setCobrar] = useState([]);
      const [reagendado, setReagendado] = useState([]);
      const [totalPagos, setTotalPagos] = useState("");
      const [noPagos, setNoPagos] = useState("");
      const [nomMeses, setNomMeses] = useState("");
      const [openHistorial, setOpenHistorial] = useState(false);
      const [pagos, setPagos] = useState([]);

      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );

      const theme = createTheme();

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerComentarios();
                  actualizarNoReagendas();
                  modificarEstadoPrenecesidad();
                  cambiarEstadoPagos();
                  actualizarContratosCobrosHoy();
                  actualizarContratosPendiente();
                  actualizarContratosPendienteReagendado();
                  traerTotalPagos();
                  traerCobros();
                  setActualizar(false);
            }
      }, [actualizar, selectedAgencia]);

      const traerCobros = () => {
            console.log(comentarios);
            fetch(`https://dejandohuellas.syfacol.com/contratos/cobros/${selectedAgencia}`)
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);

                        res.map((r) => {
                              const comentario = comentarios.filter(
                                    (com) => com.IdCliente === r.Cedula
                              );
                              r.Comentarios = comentario;
                        });

                        let cobrar = res.filter(
                              (c) => c.EstadoPago === "COBRO HOY"
                        );
                        let pendientes = res.filter(
                              (c) => c.EstadoPago === "PENDIENTE"
                        );
                        let pagados = res.filter(
                              (c) => c.EstadoPago === "PAGÓ"
                        );
                        let reagendado = res.filter(
                              (c) => c.EstadoPago === "REAGENDADO"
                        );

                        setPendientes(pendientes);
                        setPagados(pagados);
                        setCobrar(cobrar);
                        setReagendado(reagendado);
                        setCobros(res);
                  });
      };

      const traerCobrosMañana = () => {
            fecha.setDate(fecha.getDate() + 1);
            setVisible(!visible);
            setTitulo("para Mañana");
            console.log(comentarios);
            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/cobrosmanana/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);

                        res.map((r) => {
                              const comentario = comentarios.filter(
                                    (com) => com.IdCliente === r.Cedula
                              );
                              r.Comentarios = comentario;
                        });

                        let cobrar = res.filter(
                              (c) => c.EstadoPago === "COBRO HOY"
                        );
                        let pendientes = res.filter(
                              (c) => c.EstadoPago === "PENDIENTE"
                        );
                        let pagados = res.filter(
                              (c) => c.EstadoPago === "PAGÓ"
                        );
                        let reagendado = res.filter(
                              (c) => c.EstadoPago === "REAGENDADO"
                        );

                        setPendientes(pendientes);
                        setPagados(pagados);
                        setCobrar(cobrar);
                        setReagendado(reagendado);
                        setCobros(res);
                  });
      };

      const actualizacion = () => {
            setActualizar(!actualizar);
            setVisible(!visible);
            setTitulo("para Hoy");
            fecha.setDate(fecha.getDate() - 1);
      };

      const traerComentarios = () => {
            fetch("https://dejandohuellas.syfacol.com/contratos/todosComentarios")
                  .then((res) => res.json())
                  .then((res) => setComentarios(res));
      };

      const cambiarEstadoPagos = () => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/modificarEstadosPagos",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const actualizarContratosPendiente = () => {
            //ACTUALIZA EL ESTADO DE LOS CONTRATOS DE 'COBRO HOY' A 'PENDIENTE'
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/actualizarContratosPendiente",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const actualizarContratosPendienteReagendado = () => {
            //ACTUALIZA EL ESTADO DE LOS CONTRATOS DE 'REAGENDADO' A 'PENDIENTE'
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/actualizarReagendadoPendiente",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const actualizarContratosCobrosHoy = () => {
            //ACTUALIZA EL ESTADO DE LOS CONTRATOS DE 'PAGÓ' A 'COBRO HOY'
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/actualizarContratosCobros",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const actualizarNoReagendas = () => {
            //ACTUALIZA EL ESTADO DE CONTROL
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/actualizarNoReagendas",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const modificarEstadoPrenecesidad = () => {
            //Modifica el estado de los planes PRENECESIDAD

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/modificarEstado",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const nombreMes = (NoContrato) => {
            fetch(`https://dejandohuellas.syfacol.com/contratos/nomFechas/${NoContrato}`)
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setNomMeses(r.CopyProximoPago));
                  });
      };

      const [anchorEl, setAnchorEl] = useState(null);

      const handlePopoverOpen = (date, e) => {
            setData(date);
            setAnchorEl(e.currentTarget);
      };

      const handlePopoverClose = () => {
            setData("");
            setAnchorEl(null);
      };

      const open = Boolean(anchorEl);

      const meses = [
            {
                  No: 1,
                  Mes: "1 MES",
            },
            {
                  No: 2,
                  Mes: "2 MESES",
            },
            {
                  No: 3,
                  Mes: "3 MESES",
            },
            {
                  No: 4,
                  Mes: "4 MESES",
            },
            {
                  No: 5,
                  Mes: "5 MESES",
            },
            {
                  No: 6,
                  Mes: "6 MESES",
            },
            {
                  No: 7,
                  Mes: "7 MESES",
            },
            {
                  No: 8,
                  Mes: "8 MESES",
            },
            {
                  No: 9,
                  Mes: "9 MESES",
            },
            {
                  No: 10,
                  Mes: "10 MESES",
            },
            {
                  No: 11,
                  Mes: "11 MESES",
            },
            {
                  No: 12,
                  Mes: "12 MESES",
            },
      ];

      const columns = [
            {
                  field: "Nombres",
                  headerName: "Cliente",
                  width: 260,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Direccion",
                  headerName: "Dirección",
                  width: 210,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Telefono",
                  headerName: "Teléfono",
                  width: 120,
                  headerAlign: "center",
                  editable: true,
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>{params.row.Telefono}</b>
                        </div>
                  ),
            },
            {
                  field: "EstadoPagos",
                  headerName: "Meses Colgados",
                  width: 160,
                  headerAlign: "center",

                  renderCell: (params) => (
                        <div
                              aria-owns={
                                    open ? "mouse-over-popover" : undefined
                              }
                              aria-haspopup="true"
                              onMouseEnter={(e) =>
                                    handlePopoverOpen(params.row, e)
                              }
                              onMouseLeave={handlePopoverClose}
                              style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                    fontSize: "14px",
                                    color: `${
                                          (params.row.MesesEnMora === 1 &&
                                                "black") ||
                                          "white"
                                    }`,

                                    background: `${
                                          (params.row.MesesEnMora === 0 &&
                                                " #2ed600") ||
                                          (params.row.MesesEnMora === 0 &&
                                                "blue") ||
                                          (params.row.MesesEnMora === 1 &&
                                                "yellow") ||
                                          (params.row.MesesEnMora === 2 &&
                                                "red") ||
                                          "red"
                                    }`,
                              }}
                        >
                              <b>
                                    {(params.row.EstadoPagos === "AL DIA" &&
                                          params.row.EstadoPagos) ||
                                          "EN MORA " +
                                                (params.row.MesesEnMora + 1) +
                                                " MES/ES"}
                              </b>
                        </div>
                  ),
            },
            {
                  field: "EstadoPago",
                  headerName: "Estado",
                  width: 140,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <Chip
                                    style={{
                                          fontSize: "14px",
                                          color: `${
                                                (params.row.EstadoPago ===
                                                      "COBRO HOY" &&
                                                      "black") ||
                                                (params.row.EstadoPago ===
                                                      "EN MORA" &&
                                                      "white") ||
                                                (params.row.EstadoPago ===
                                                      "PAGÓ" &&
                                                      "white") ||
                                                (params.row.EstadoPago ===
                                                      "PENDIENTE" &&
                                                      "white") ||
                                                (params.row.EstadoPago ===
                                                      "REAGENDADO" &&
                                                      "black") ||
                                                (params.row.EstadoPago ===
                                                      "PARTICULAR" &&
                                                      "white")
                                          }`,

                                          background: `${
                                                (params.row.EstadoPago ===
                                                      "COBRO HOY" &&
                                                      "white") ||
                                                (params.row.EstadoPago ===
                                                      "EN MORA" &&
                                                      "#094480") ||
                                                (params.row.EstadoPago ===
                                                      "PAGÓ" &&
                                                      "green") ||
                                                (params.row.EstadoPago ===
                                                      "PENDIENTE" &&
                                                      "#ff0000") ||
                                                (params.row.EstadoPago ===
                                                      "REAGENDADO" &&
                                                      "yellow") ||
                                                (params.row.EstadoPago ===
                                                      "PARTICULAR" &&
                                                      "black")
                                          }`,
                                    }}
                                    label={<b>{params.row.EstadoPago}</b>}
                              />
                        </div>
                  ),
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 270,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Pago"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalPagos(params.row)
                                          }
                                    >
                                          <MonetizationOnIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Historial de Pagos"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalHistorial(params.row)
                                          }
                                    >
                                          <HistoryIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"ReAgendar Dia de Cobro"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalAgendar(params.row)
                                          }
                                    >
                                          <DateRangeIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Agregar Comentarios"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalComentarios(
                                                      params.row
                                                )
                                          }
                                    >
                                          <AddCommentIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Comentarios"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalVerComentarios(
                                                      params.row.Comentarios
                                                )
                                          }
                                    >
                                          <Badge
                                                badgeContent={
                                                      params.row.Comentarios
                                                            .length
                                                }
                                                color="primary"
                                          >
                                                <ChatIcon />
                                          </Badge>
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
            {
                  field: "formaPago",
                  headerName: "Forma de Pago",
                  width: 150,
                  headerAlign: "center",
            },

            {
                  field: "valorCuotas",
                  headerName: "Cuota",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>
                                    {formatterPeso.format(
                                          params.row.valorCuotas
                                    )}
                              </b>
                        </div>
                  ),
            },
            {
                  field: "Acumulado",
                  headerName: "Cuotas Acumuladas",
                  width: 130,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>
                                    {formatterPeso.format(params.row.Acumulado)}
                              </b>
                        </div>
                  ),
            },
      ];

      const monthNames = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
      ];

      const getLongMonthName = (date) => {
            //OBTIENE EL NOMBRE LARGO DEL MES
            return monthNames[date.getMonth()];
      };

      const handleChange = (e) => {
            filtrarContratos(e.target.value);
      };

      const filtrarContratos = (termino) => {
            if (termino === "") {
                  setActualizar(true);
            } else {
                  fetch(
                        `https://dejandohuellas.syfacol.com/contratos/filtroCobros/${termino}/${selectedAgencia}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              res.map((r) => {
                                    const comentario = comentarios.filter(
                                          (com) => com.IdCliente === r.Cedula
                                    );
                                    r.Comentarios = comentario;
                              });
                              setCobros(res);
                        });
            }
      };

      const guardarComentario = (values) => {
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch("https://dejandohuellas.syfacol.com/contratos/comentarios", requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );
            setActualizar(true);
            setData("");
            setOpenComentarios(!openComentarios);
      };

      const reAgendar = (values) => {
            console.log(values.NoReagendas);
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            /* if(data.NoReagendas >= 1){
              fetch(
                  `https://dejandohuellas.syfacol.com/contratos/reAgendar2/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                 .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  )

            }else{*/
            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/reAgendar/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            /*}*/

            setActualizar(true);
            setData("");
            setOpenAgendar(!openAgendar);
      };

      const modificar = (values) => {
            if (data.EstadoPago === "PARTICULAR") {
                  contratoParticular();
            } else {
                  contratoNormal(values);
            }
      };

      const traerPagos = (NoContrato) => {
            fetch(`https://dejandohuellas.syfacol.com/pagos/historial/${NoContrato}`)
                  .then((res) => res.json())
                  .then((res) => setPagos(res));
      };

      const contratoParticular = () => {
            const obj = {
                  Estado: "SERVICIO PAGO",
                  EstadoPagos: "SERVICIO PAGO",
                  ProximoPago: "0000-00-00",
                  CopyProximoPago: "0000-00-00",
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/modificar/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const contratoNormal = (values) => {
            let noMeses = values.Meses;
            let fecProximoPago = new Date(data.CopyProximoPago1);

            console.log(fecProximoPago);
            let año = fecProximoPago.getFullYear();
            let mes = fecProximoPago.getMonth() + 1;
            let diasMes = new Date(año, mes, 0).getDate();
            console.log(diasMes);

            let arrayDiasMes = [];
            for (let i = 0; i < diasMes; i++) {
                  arrayDiasMes.push(i + 1);
                  console.log(arrayDiasMes);
            }

            console.log(arrayDiasMes.length);

            if (arrayDiasMes.includes(data.DiaPago)) {
                  fecProximoPago =
                        fecProximoPago.getFullYear() +
                        "-" +
                        (fecProximoPago.getMonth() + 1) +
                        "-" +
                        data.DiaPago; //Realizo el pago normla
            } else {
                  fecProximoPago =
                        fecProximoPago.getFullYear() +
                        "-" +
                        (fecProximoPago.getMonth() + 1) +
                        "-" +
                        arrayDiasMes.length;
            }

            console.log(fecProximoPago);
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  //body: JSON.stringify(obj),
            };

            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/contratosEstadoPago/${fecProximoPago}/${noMeses}/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const descontarCuotas = (values) => {
            if (data.Prenecesidad === "SI") {
                  data.CuotasRestantes = data.CuotasRestantes - values.Meses;
                  data.Saldo = data.Saldo - values.Valor;
                  data.valorCuotas = data.Saldo / data.CuotasRestantes;
            } else {
                  data.CuotasRestantes = data.CuotasRestantes - values.Meses;
                  data.Saldo = data.Saldo - values.Valor;
            }
            let obj = {
                  CuotasRestantes: data.CuotasRestantes,
                  Saldo: data.Saldo,
                  valorCuotas: data.valorCuotas,
            };

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };
            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/contratosEstado/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const eliminarComentarios = (dato) => {
            const requestInit = {
                  method: "DELETE",
            };
            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/eliminarComentarios/${dato.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            traerComentarios(dato.IdCliente);
            setActualizar(true);
      };

      const guardarPagos = (values) => {
            descontarCuotas(values);
            modificar(values);

            values.Fecha =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();

            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch("https://dejandohuellas.syfacol.com/pagos/registro", requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              icon: "success",
                              confirmButtonText: "Listo",
                              confirmButtonColor: "#4c4",
                        }).then((res) => {
                              if (res.isConfirmed) {
                                    window.location = `/comprobante/${data.NoContrato}`;
                              }
                        })
                  );

            setActualizar(true);
            setData("");
            setOpenPago(!openPago);
      };

      const abrirModalHistorial = (datos) => {
            setData(datos);
            traerPagos(datos.NoContrato);
            setOpenHistorial(!openHistorial);
      };

      const cerrarModalHistorial = () => {
            //setActualizar(!actualizar);
            setOpenHistorial(!openHistorial);
            setData("");
      };

      const abrirModalPagos = (dato) => {
            setData(dato);
            setOpenPago(!openPago);
      };

      const cerrarModalPagos = () => {
            setOpenPago(!openPago);
            setData("");
      };

      const abrirModalAgendar = (dato) => {
            setData(dato);
            console.log(data);
            setOpenAgendar(!openAgendar);
      };

      const cerrarModalAgendar = () => {
            setOpenAgendar(!openAgendar);
            setData("");
      };

      const abrirModalComentarios = (dato) => {
            setData(dato);
            setOpenComentarios(!openComentarios);
      };

      const cerrarModalComentarios = () => {
            setOpenComentarios(!openComentarios);
            setData("");
      };

      const abrirModalVerComentarios = (datos) => {
            setComents(datos);
            setOpenVerComentarios(!openVerComentarios);
      };

      const cerrarModalVerComentarios = () => {
            setOpenVerComentarios(!openVerComentarios);
            setActualizar(true);
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const traerTotalPagos = () => {
            fetch(
                  `https://dejandohuellas.syfacol.com/pagos/totalPagosHoy/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map(
                              (r) => (
                                    setNoPagos(r.NO_PAGOS),
                                    setTotalPagos(r.TOTAL_PAGOS)
                              )
                        );
                  });
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Popover
                              id="mouse-over-popover"
                              sx={{
                                    pointerEvents: "none",
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                              }}
                              transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                        >
                              <Typography
                                    align="center"
                                    variant="subtitle2"
                                    sx={{ pr: 1, pl: 1, pt: 1 }}
                              >
                                    <b>EN MORA DESDE: </b>
                              </Typography>
                              <Typography align="center" variant="subtitle2">
                                    <b>{data.CopyProximoPago}</b>
                              </Typography>
                        </Popover>

                        <Dialog open={openPago} onClose={openPago}>
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha: "",
                                                Meses: "",
                                                Detalle: "",
                                                Valor: "",
                                                MedioPago: "",
                                                ElaboradoPor:
                                                      sessionStorage.getItem(
                                                            "user"
                                                      ),
                                                IdContrato: data.NoContrato,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Detalle: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Valor: Yup.number(
                                                      "* Solo se admiten números"
                                                )
                                                      .positive(
                                                            "* No se aceptan valores negativos"
                                                      )
                                                      .required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                guardarPagos(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "10px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Realizar
                                                                        Pago
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  component="h3"
                                                                  style={{
                                                                        marginBottom:
                                                                              "-20px",
                                                                        color: "#800000",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Cliente:
                                                                  </b>{" "}
                                                                  {data.Nombres}
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Meses
                                                                        que Paga
                                                                        *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Meses"
                                                                        value={
                                                                              values.Meses
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Meses &&
                                                                                    errors.Meses
                                                                        )}
                                                                        helperText={
                                                                              touched.Meses &&
                                                                              errors.Meses
                                                                        }
                                                                  >
                                                                        {meses.map(
                                                                              (
                                                                                    m
                                                                              ) => (
                                                                                    <MenuItem
                                                                                          value={
                                                                                                m.No
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                m.Mes
                                                                                          }
                                                                                    </MenuItem>
                                                                              )
                                                                        )}
                                                                  </Select>
                                                            </FormControl>

                                                            <TextField
                                                                  name="Detalle"
                                                                  label="Detalle *"
                                                                  multiline
                                                                  maxRows={4}
                                                                  error={Boolean(
                                                                        touched.Detalle &&
                                                                              errors.Detalle
                                                                  )}
                                                                  helperText={
                                                                        touched.Detalle &&
                                                                        errors.Detalle
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  value={
                                                                        values.Detalle
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <TextField
                                                                  name="Valor"
                                                                  label="Valor *"
                                                                  error={Boolean(
                                                                        touched.Valor &&
                                                                              errors.Valor
                                                                  )}
                                                                  helperText={
                                                                        touched.Valor &&
                                                                        errors.Valor
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Valor
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />
                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Medio de
                                                                        Pago *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="MedioPago"
                                                                        value={
                                                                              values.MedioPago
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.MedioPago &&
                                                                                    errors.MedioPago
                                                                        )}
                                                                        helperText={
                                                                              touched.MedioPago &&
                                                                              errors.MedioPago
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "AHORRO A LA  MANO"
                                                                              }
                                                                        >
                                                                              AHORRO
                                                                              A
                                                                              LA
                                                                              MANO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "BANCOLOMBIA"
                                                                              }
                                                                        >
                                                                              BANCOLOMBIA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "DAVIPLATA"
                                                                              }
                                                                        >
                                                                              DAVIPLATA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "EFECTIVO"
                                                                              }
                                                                        >
                                                                              EFECTIVO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "NEQUI"
                                                                              }
                                                                        >
                                                                              NEQUI
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Registrar
                                                                  </Button>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalPagos()
                                                                        }
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>
                        <Dialog open={openAgendar} onClose={openAgendar}>
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                FechaReprogramada: null,
                                                ProximoPago: data.ProximoPago,
                                                NoReagendas:
                                                      data.NoReagendas + 1,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                FechaReprogramada: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                reAgendar(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "-10px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Re-Agendar
                                                                        el Día
                                                                        de Pago
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <MuiPickersUtilsProvider
                                                                  utils={
                                                                        DateFnsUtils
                                                                  }
                                                                  locale={
                                                                        esLocale
                                                                  }
                                                            >
                                                                  <KeyboardDatePicker
                                                                        size="medium"
                                                                        name="FechaReprogramada"
                                                                        format="dd/MM/yyyy"
                                                                        margin="normal"
                                                                        label="Fecha Reprogramada *"
                                                                        fullWidth
                                                                        // inputVariant="filled"
                                                                        value={
                                                                              values.FechaReprogramada
                                                                        }
                                                                        error={Boolean(
                                                                              touched.FechaReprogramada &&
                                                                                    errors.FechaReprogramada
                                                                        )}
                                                                        helperText={
                                                                              touched.FechaReprogramada &&
                                                                              errors.FechaReprogramada
                                                                        }
                                                                        onChange={(
                                                                              value
                                                                        ) =>
                                                                              setFieldValue(
                                                                                    "FechaReprogramada",
                                                                                    value
                                                                              )
                                                                        }
                                                                        KeyboardButtonProps={{
                                                                              "aria-label":
                                                                                    "change date",
                                                                        }}
                                                                  />
                                                            </MuiPickersUtilsProvider>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={4}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Registrar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalAgendar()
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog
                              open={openComentarios}
                              onClose={openComentarios}
                        >
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha: new Date(),
                                                Comentario: "",
                                                IdCliente: data.Cedula,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Fecha: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                                Comentario:
                                                      Yup.string().required(
                                                            "* Requerido"
                                                      ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                guardarComentario(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "6px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Agregar
                                                                        Comentario
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <MuiPickersUtilsProvider
                                                                  utils={
                                                                        DateFnsUtils
                                                                  }
                                                                  locale={
                                                                        esLocale
                                                                  }
                                                            >
                                                                  <KeyboardDatePicker
                                                                        size="small"
                                                                        name="Fecha"
                                                                        format="dd/MM/yyyy"
                                                                        margin="normal"
                                                                        label="Fecha *"
                                                                        fullWidth
                                                                        // inputVariant="filled"
                                                                        value={
                                                                              values.Fecha
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Fecha &&
                                                                                    errors.Fecha
                                                                        )}
                                                                        helperText={
                                                                              touched.Fecha &&
                                                                              errors.Fecha
                                                                        }
                                                                        onChange={(
                                                                              value
                                                                        ) =>
                                                                              setFieldValue(
                                                                                    "Fecha",
                                                                                    value
                                                                              )
                                                                        }
                                                                        KeyboardButtonProps={{
                                                                              "aria-label":
                                                                                    "change date",
                                                                        }}
                                                                  />
                                                            </MuiPickersUtilsProvider>

                                                            <TextField
                                                                  id="filled-multiline-flexible"
                                                                  name="Comentario"
                                                                  label="Comentario *"
                                                                  multiline
                                                                  maxRows={4}
                                                                  value={
                                                                        values.Comentario
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  //variant="filled"
                                                                  fullWidth
                                                                  margin="normal"
                                                            />
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Registrar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalComentarios()
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog
                              open={openVerComentarios}
                              onClose={openVerComentarios}
                        >
                              <div id="content" style={{ padding: "5px" }}>
                                    <DialogTitle>
                                          <div
                                                style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent:
                                                            "flex-end",
                                                }}
                                          >
                                                <Tooltip
                                                      interactive
                                                      placement={"top"}
                                                      title={"Cerrar"}
                                                >
                                                      <IconButton
                                                            aria-label="details"
                                                            style={{
                                                                  color: "#ff0000",
                                                            }}
                                                            size={"medium"}
                                                            onClick={() =>
                                                                  cerrarModalVerComentarios()
                                                            }
                                                      >
                                                            <CancelRoundedIcon />
                                                      </IconButton>
                                                </Tooltip>
                                          </div>
                                          <Typography
                                                variant="h5"
                                                component="h3"
                                                align="center"
                                                style={{ marginBottom: "7px" }}
                                          >
                                                <b>Comentarios</b>
                                          </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                          <Stack
                                                sx={{ width: "100%" }}
                                                spacing={2}
                                          >
                                                {coments.map((c) => (
                                                      <Alert
                                                            icon={
                                                                  <ChatIcon fontSize="inherit" />
                                                            }
                                                            severity="info"
                                                            action={
                                                                  <Button
                                                                        color="inherit"
                                                                        size="small"
                                                                        onClick={() =>
                                                                              eliminarComentarios(
                                                                                    c
                                                                              )
                                                                        }
                                                                  >
                                                                        X
                                                                  </Button>
                                                            }
                                                      >
                                                            <AlertTitle>
                                                                  {c.Fecha}
                                                            </AlertTitle>
                                                            {c.Comentario}
                                                            {/*   <strong>
                                                            check it out!
                                                </strong>*/}
                                                      </Alert>
                                                ))}
                                          </Stack>
                                    </DialogContent>
                                    <DialogActions></DialogActions>
                              </div>
                        </Dialog>

                        <Dialog open={openHistorial} onClose={openHistorial}>
                              <div id="content" style={{ padding: "5px" }}>
                                    <DialogTitle>
                                          <div
                                                style={{
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent:
                                                            "flex-end",
                                                }}
                                          >
                                                <Tooltip
                                                      interactive
                                                      placement={"top"}
                                                      title={"Cerrar"}
                                                >
                                                      <IconButton
                                                            aria-label="details"
                                                            style={{
                                                                  color: "#ff0000",
                                                            }}
                                                            size={"medium"}
                                                            onClick={() =>
                                                                  cerrarModalHistorial()
                                                            }
                                                      >
                                                            <CancelRoundedIcon />
                                                      </IconButton>
                                                </Tooltip>
                                          </div>
                                          <Typography
                                                variant="h5"
                                                component="h3"
                                                align="center"
                                                style={{ marginBottom: "7px" }}
                                          >
                                                <b>
                                                      Historial de Pagos de{" "}
                                                      {data.Nombres}
                                                </b>
                                          </Typography>
                                    </DialogTitle>
                                    <DialogContent>
                                          <Stack
                                                sx={{ width: "100%" }}
                                                spacing={2}
                                          >
                                                {pagos.map((p) => (
                                                      <Alert
                                                            icon={
                                                                  <ChatIcon fontSize="inherit" />
                                                            }
                                                            severity="info"
                                                            action={
                                                                  <Button
                                                                        color="inherit"
                                                                        size="small"
                                                                  >
                                                                        X
                                                                  </Button>
                                                            }
                                                      >
                                                            <AlertTitle>
                                                                  Pagó:{" "}
                                                                  {p.Fecha}
                                                            </AlertTitle>
                                                            <AlertTitle>
                                                                  {formatterPeso.format(
                                                                        p.Valor
                                                                  )}
                                                            </AlertTitle>
                                                            {p.Detalle}
                                                            {/*   <strong>
                                                            check it out!
                                                </strong>*/}
                                                      </Alert>
                                                ))}
                                          </Stack>
                                    </DialogContent>
                                    <DialogActions></DialogActions>
                              </div>
                        </Dialog>

                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>
                                          Cobros {titulo}
                                          {" " +
                                                fecha.getDate() +
                                                "/" +
                                                getLongMonthName(fecha) +
                                                "/" +
                                                fecha.getFullYear()}
                                    </b>
                              </Typography>
                        </Grid>

                        <Grid container md={12}>
                              <Grid
                                    item
                                    xs={10}
                                    sm={6}
                                    md={6}
                                    justifyContent="flex-end"
                              >
                                    <TextField
                                          name="filtro"
                                          label="Buscar..."
                                          size="small"
                                          onChange={handleChange}
                                          variant="standard"
                                          margin="normal"
                                          InputProps={{
                                                endAdornment: (
                                                      <InputAdornment position="start">
                                                            <SearchIcon />
                                                      </InputAdornment>
                                                ),
                                          }}
                                    />
                              </Grid>
                              <Grid
                                    item
                                    xs={2}
                                    sm={6}
                                    md={6}
                                    style={{
                                          display: "flex",
                                          justifyContents: "flex-end",
                                    }}
                              >
                                    <IconButton aria-label="cart">
                                          <StyledBadge
                                                badgeContent={cobros.length}
                                                style={{
                                                      color: "#ff0000",
                                                }}
                                          >
                                                <NotificationsIcon />
                                          </StyledBadge>
                                    </IconButton>
                              </Grid>

                              <Grid item xs={12} sm={4} md={4}>
                                    <ThemeProvider theme={theme}>
                                          <table className="table table-bordered table-sm mt-2">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "red",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="4">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              DE
                                                                              HOY
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    cobrar.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              HAN
                                                                              PAGADO
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    noPagos
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              PENDIENTES
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    pendientes.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              REAGENDADOS
                                                                              PARA
                                                                              HOY
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    reagendado.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TOTAL
                                                                              DE
                                                                              RECAUDOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    totalPagos
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </ThemeProvider>
                              </Grid>

                              <Grid container md={12} justifyContent="start">
                                    {(visible && (
                                          <Tooltip
                                                interactive
                                                placement={"top"}
                                                title={"Ver Cobros Para Mañana"}
                                          >
                                                <IconButton
                                                      aria-label="details"
                                                      style={{
                                                            color: "#ff0000",
                                                      }}
                                                      size={"medium"}
                                                      onClick={() =>
                                                            traerCobrosMañana()
                                                      }
                                                >
                                                      <WbSunnyIcon />
                                                </IconButton>
                                          </Tooltip>
                                    )) || (
                                          <Tooltip
                                                interactive
                                                placement={"top"}
                                                title={"Actualizar"}
                                          >
                                                <IconButton
                                                      aria-label="details"
                                                      style={{
                                                            color: "#ff0000",
                                                      }}
                                                      size={"medium"}
                                                      onClick={() =>
                                                            actualizacion()
                                                      }
                                                >
                                                      <ManageHistoryIcon />
                                                </IconButton>
                                          </Tooltip>
                                    )}
                              </Grid>
                        </Grid>

                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) =>
                                                      row.NoContrato
                                                }
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Contratos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={cobros}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default CobrosHoy;
