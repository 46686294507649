import React, { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IconButton, Tooltip } from "@material-ui/core";

export const ExportToExcel = ({ pagos, gastos, fileName }) => {
      const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const exportToCSV = (pagos, gastos, fileName) => {
            const ws = XLSX.utils.json_to_sheet(pagos);
            const ws1 = XLSX.utils.json_to_sheet(gastos);
            XLSX.utils.sheet_add_aoa(ws, [
                  [
                        "FECHA",
                        "N° RECIBO",
                        "DETALLE",
                        "VALOR",
                        "CLIENTE",
                        "EMPLEADO",
                  ],
            ]);

            XLSX.utils.sheet_add_aoa(ws1, [
                  ["FECHA", "DETALLE", "VALOR", "EMPLEADO"],
            ]);

            const wb = {
                  Sheets: { COBROS: ws, GASTOS: ws1 },
                  SheetNames: ["COBROS", "GASTOS"],
            };
            const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
            });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
      };

      return (
            <Tooltip interactive placement={"top"} title={"Exportar a Excel"}>
                  <IconButton
                        aria-label="details"
                        style={{
                              color: "white",
                              background: "green",
                        }}
                        size={"medium"}
                        onClick={(e) => exportToCSV(pagos, gastos, fileName)}
                  >
                        <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                        >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                    d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z"
                                    fill="rgba(255,255,255,1)"
                              />
                        </svg>
                  </IconButton>
            </Tooltip>
      );
};
