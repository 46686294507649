import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./footer/Footer";
import { useSelector } from "react-redux";

const FormContrato = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [agencias, setAgencias] = useState([]);
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );
      const rol = sessionStorage.getItem("rol");

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
            traerAgencias();
      }, [selectedAgencia]);

      const guardarContrato = (values) => {
            values.IdAgencia = selectedAgencia;
            values.Codigo = selectedAgencia === 1 ? "DHF-" : "DHN-";

            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              icon: "success",
                              confirmButtonText: "Listo",
                              confirmButtonColor: "#4c4",
                        }).then((res) => {
                              if (res.isConfirmed) {
                                    window.location = `/opciones/${values.Cedula}`;
                              }
                        })
                  );
      };

      const traerAgencias = () => {
            fetch("https://dejandohuellas.syfacol.com/agencias/")
                  .then((res) => res.json())
                  .then((res) => setAgencias(res));
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              alignItems="center"
                              style={{ marginTop: "70px" }}
                        >
                              <Grid
                                    container
                                    justifyContent="center"
                                    xs={10}
                                    sm={10}
                                    md={9}
                              >
                                    <Formik
                                          initialValues={{
                                                FecInicial: null,
                                                FecRenovacion: null,
                                                FecNacimiento: null,
                                                Nombres: "",
                                                Cedula: "",
                                                Sexo: "",
                                                EstadoCivil: "",
                                                Direccion: "",
                                                Barrio: "",
                                                Telefono: "",
                                                Email: "",
                                                IdUser: sessionStorage.getItem(
                                                      "idUser"
                                                ),
                                                IdAgencia: "",
                                                Codigo: "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                                FecInicial: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                                FecRenovacion: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                                FecNacimiento: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                                Nombres: Yup.string()
                                                      .matches(
                                                            /^[A-Za-z\sÀ-ÿ]+$/,
                                                            "* Solo se admiten letras"
                                                      )
                                                      .required("* Requerido"),

                                                Cedula: Yup.number(
                                                      "* Solo se admiten números"
                                                ).required("* Requerido"),
                                                Sexo: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                EstadoCivil:
                                                      Yup.string().required(
                                                            "* Requerido"
                                                      ),
                                                Barrio: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Direccion:
                                                      Yup.string().required(
                                                            "* Requerido"
                                                      ),
                                                Telefono: Yup.number(
                                                      "* Solo se admiten números"
                                                ).required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                guardarContrato(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                handleBlur,
                                                handleChange,
                                                setFieldValue,
                                          }) => (
                                                <Form>
                                                      <Grid
                                                            container
                                                            spacing={2}
                                                            md={12}
                                                            xs={12}
                                                            sm={12}
                                                            justifyContent="center"
                                                            p={5}
                                                      >
                                                            <Grid
                                                                  item
                                                                  md={12}
                                                                  xs={12}
                                                                  sm={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Typography
                                                                        variant="h5"
                                                                        component="h2"
                                                                        align="center"
                                                                        style={{
                                                                              marginTop:
                                                                                    "12px",
                                                                              color: "#1b1b1b",
                                                                        }}
                                                                  >
                                                                        <b>
                                                                              CONTRATO
                                                                              DE
                                                                              AFILIACIÓN
                                                                              INDIVIDUAL
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  md={12}
                                                                  xs={12}
                                                                  sm={12}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h4"
                                                                  >
                                                                        <b>
                                                                              *
                                                                              VIGENCIA
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="FecInicial"
                                                                              format="dd/MM/yyyy"
                                                                              margin="none"
                                                                              label="Fecha Inicial *"
                                                                              fullWidth
                                                                              inputVariant="filled"
                                                                              value={
                                                                                    values.FecInicial
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.FecInicial &&
                                                                                          errors.FecInicial
                                                                              )}
                                                                              helperText={
                                                                                    touched.FecInicial &&
                                                                                    errors.FecInicial
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "FecInicial",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="FecRenovacion"
                                                                              format="dd/MM/yyyy"
                                                                              margin="none"
                                                                              label="Fecha de Renovación *"
                                                                              fullWidth
                                                                              inputVariant="filled"
                                                                              value={
                                                                                    values.FecRenovacion
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.FecRenovacion &&
                                                                                          errors.FecRenovacion
                                                                              )}
                                                                              helperText={
                                                                                    touched.FecRenovacion &&
                                                                                    errors.FecRenovacion
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "FecRenovacion",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h4"
                                                                  >
                                                                        <b>
                                                                              *
                                                                              INFORMACIÓN
                                                                              DEL
                                                                              TITULAR
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Nombres"
                                                                        label="Nombre y Apellidos *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Nombres
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Nombres &&
                                                                                    errors.Nombres
                                                                        )}
                                                                        helperText={
                                                                              touched.Nombres &&
                                                                              errors.Nombres
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Cedula"
                                                                        label="Cedula *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Cedula
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Cedula &&
                                                                                    errors.Cedula
                                                                        )}
                                                                        helperText={
                                                                              touched.Cedula &&
                                                                              errors.Cedula
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="small"
                                                                              name="FecNacimiento"
                                                                              format="dd/MM/yyyy"
                                                                              margin="none"
                                                                              label="Fecha de Nacimiento *"
                                                                              fullWidth
                                                                              inputVariant="filled"
                                                                              value={
                                                                                    values.FecNacimiento
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.FecNacimiento &&
                                                                                          errors.FecNacimiento
                                                                              )}
                                                                              helperText={
                                                                                    touched.FecNacimiento &&
                                                                                    errors.FecNacimiento
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "FecNacimiento",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <FormControl
                                                                        variant="filled"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Sexo
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="Sexo"
                                                                              value={
                                                                                    values.Sexo
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Sexo &&
                                                                                          errors.Sexo
                                                                              )}
                                                                              helperText={
                                                                                    touched.Sexo &&
                                                                                    errors.Sexo
                                                                              }
                                                                        >
                                                                              <MenuItem
                                                                                    value={
                                                                                          "M"
                                                                                    }
                                                                              >
                                                                                    MASCULINO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "F"
                                                                                    }
                                                                              >
                                                                                    FEMENINO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "OTRO"
                                                                                    }
                                                                              >
                                                                                    OTRO
                                                                              </MenuItem>
                                                                        </Select>
                                                                  </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <FormControl
                                                                        variant="filled"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Estado
                                                                              Civil
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="EstadoCivil"
                                                                              value={
                                                                                    values.EstadoCivil
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.EstadoCivil &&
                                                                                          errors.EstadoCivil
                                                                              )}
                                                                              helperText={
                                                                                    touched.EstadoCivil &&
                                                                                    errors.EstadoCivil
                                                                              }
                                                                        >
                                                                              <MenuItem
                                                                                    value={
                                                                                          "CASADO"
                                                                                    }
                                                                              >
                                                                                    CASADO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "SOLTERO"
                                                                                    }
                                                                              >
                                                                                    SOLTERO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "UNIÓN LIBRE"
                                                                                    }
                                                                              >
                                                                                    UNIÓN
                                                                                    LIBRE
                                                                              </MenuItem>
                                                                        </Select>
                                                                  </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Barrio"
                                                                        label="Barrio *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Barrio
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Barrio &&
                                                                                    errors.Barrio
                                                                        )}
                                                                        helperText={
                                                                              touched.Barrio &&
                                                                              errors.Barrio
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Direccion"
                                                                        label="Dirección de Residencia *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Direccion
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Direccion &&
                                                                                    errors.Direccion
                                                                        )}
                                                                        helperText={
                                                                              touched.Direccion &&
                                                                              errors.Direccion
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Telefono"
                                                                        label="Télefono *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Telefono
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Telefono &&
                                                                                    errors.Telefono
                                                                        )}
                                                                        helperText={
                                                                              touched.Telefono &&
                                                                              errors.Telefono
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={
                                                                        rol ===
                                                                        "2"
                                                                              ? 12
                                                                              : 6
                                                                  }
                                                                  justifyContent="start"
                                                            >
                                                                  <TextField
                                                                        name="Email"
                                                                        label="E-mail *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Email
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                  />
                                                            </Grid>
                                                            {rol !== "2" ? (
                                                                  <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={6}
                                                                        justifyContent="center"
                                                                  >
                                                                        <FormControl
                                                                              variant="filled"
                                                                              fullWidth
                                                                              size="small"
                                                                        >
                                                                              <InputLabel id="demo-simple-select-filled-label">
                                                                                    Seleccione
                                                                                    la
                                                                                    Agencia
                                                                                    *
                                                                              </InputLabel>
                                                                              <Select
                                                                                    labelId="demo-simple-select-filled-label"
                                                                                    id="demo-simple-select-filled"
                                                                                    name="IdAgencia"
                                                                                    disabled={
                                                                                          true
                                                                                    }
                                                                                    value={
                                                                                          selectedAgencia
                                                                                    }
                                                                                    onBlur={
                                                                                          handleBlur
                                                                                    }
                                                                                    onChange={
                                                                                          handleChange
                                                                                    }
                                                                                    error={Boolean(
                                                                                          touched.IdAgencia &&
                                                                                                errors.IdAgencia
                                                                                    )}
                                                                                    helperText={
                                                                                          touched.IdAgencia &&
                                                                                          errors.IdAgencia
                                                                                    }
                                                                              >
                                                                                    {agencias.map(
                                                                                          (
                                                                                                a
                                                                                          ) => (
                                                                                                <MenuItem
                                                                                                      value={
                                                                                                            a.Id
                                                                                                      }
                                                                                                >
                                                                                                      {
                                                                                                            a.Ciudad
                                                                                                      }
                                                                                                </MenuItem>
                                                                                          )
                                                                                    )}
                                                                              </Select>
                                                                        </FormControl>
                                                                  </Grid>
                                                            ) : null}
                                                            <Grid
                                                                  container
                                                                  md={10}
                                                                  justifyContent="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "20px",
                                                                  }}
                                                            >
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              height: "40px",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                  >
                                                                        <Typography variant="h6">
                                                                              Registrar
                                                                        </Typography>
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};
export default FormContrato;
