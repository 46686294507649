import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataGrid /*esES*/ } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import PetsIcon from "@material-ui/icons/Pets";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FlagIcon from "@mui/icons-material/Flag";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Navigate } from "react-router-dom";

const GestionProspectos = () => {
      const [prospectos, setProspectos] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [size, setSize] = useState(0);
      const [openMascotas, setOpenMascotas] = useState(false);
      const [openState, setOpenState] = useState(false);
      const [mascotas, setMascotas] = useState([]);
      const [data, setData] = useState([]);
      const fecha = new Date();
      

      useEffect(() => {
            fetch("https://dejandohuellas.syfacol.com/prospectos")
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        setProspectos(res);
                  });
            setActualizar(false);
      }, [actualizar]);

      const columns = [
            {
                  field: "Fecha",
                  headerName: "Fecha de Registro",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack
                              direction="row"
                              spacing={1}
                              style={{ color: "#03013e" }}
                        >
                              <b>{params.row.Fecha}</b>
                        </Stack>
                  ),
            },
            {
                  field: "FechaActualizada",
                  headerName: "Ultima Modificación",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack
                              direction="row"
                              spacing={1}
                              style={{ color: "green" }}
                        >
                              <b>{params.row.FechaActualizada}</b>
                        </Stack>
                  ),
            },

            {
                  field: "Cedula",
                  headerName: "N° Documento",
                  width: 150,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Nombre",
                  headerName: "Nombre",
                  width: 280,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Telefono",
                  headerName: "Celular",
                  width: 110,
                  headerAlign: "center",
            },
            {
                  field: "Estado",
                  headerName: "Estado",
                  width: 160,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <Chip
                                    style={{
                                          fontSize: "14px",
                                          color: "white",
                                          background: `${
                                                (params.row.Estado ===
                                                      "POR LLAMAR" &&
                                                      "#db2100") ||
                                                (params.row.Estado ===
                                                      "AFILIADO" &&
                                                      "#00a70f") ||
                                                (params.row.Estado ===
                                                      "APAGADO" &&
                                                      "#094480") ||
                                                (params.row.Estado ===
                                                      "EQUIVOCADO" &&
                                                      "#430b95") ||
                                                (params.row.Estado ===
                                                      "NO LE INTERESA" &&
                                                      "#fc6b00") ||
                                                (params.row.Estado ===
                                                      "SIN MASCOTAS" &&
                                                      "black")
                                          }`,
                                    }}
                                    label={<b>{params.row.Estado}</b>}
                              />
                        </div>
                  ),
            },

            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Mascotas"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalMascotas(params.row)
                                          }
                                    >
                                          <PetsIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Estados"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEstados(params.row)
                                          }
                                    >
                                          <FlagIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Afiliar"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                (window.location = "/")
                                          }
                                    >
                                          <AssignmentIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const traerMascotasProspectos = (Id) => {
            fetch(
                  `https://dejandohuellas.syfacol.com/prospectos/traerMascotas/${Id}`
            )
                  .then((res) => res.json())
                  .then((res) => setMascotas(res));
      };

      const modificarEstado = (values) => {
            values.FechaActualizada =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  `https://dejandohuellas.syfacol.com/prospectos/modificar/${data.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(!actualizar);
            setOpenState(!openState);
      };

      const abrirModalEstados = (props) => {
            setData(props);
            setOpenState(!openState);
      };

      const cerrarModalEstados = () => {
            setOpenState(!openState);
            setData("");
      };

      const abrirModalMascotas = (prosp) => {
            setData(prosp);
            traerMascotasProspectos(prosp.Id);
            setOpenMascotas(!openMascotas);
      };

      const cerrarModalMascotas = () => {
            setActualizar(!actualizar);
            setOpenMascotas(!openMascotas);
            setData("");
            //setMascotas("");
      };

      return (
            <Container>
                  <Dialog open={openState} onClose={openState}>
                        <div style={{ padding: "15px" }}>
                              <Formik
                                    initialValues={{
                                          Estado: "",
                                          FechaActualizada: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                          Estado: Yup.string().required(
                                                "* Requerido"
                                          ),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                          modificarEstado(values);
                                          resetForm();
                                    }}
                              >
                                    {({
                                          errors,
                                          touched,
                                          values,
                                          setFieldValue,
                                          handleBlur,
                                          handleChange,
                                    }) => (
                                          <Form>
                                                <DialogTitle>
                                                      Estados
                                                </DialogTitle>

                                                <DialogContent>
                                                      <DialogContentText>
                                                            Selecciona un estado
                                                            dependiendo de la
                                                            satisfación de la
                                                            llamada.
                                                      </DialogContentText>
                                                      <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            size="small"
                                                            style={{
                                                                  marginTop:
                                                                        "20px",
                                                            }}
                                                      >
                                                            <Select
                                                                  labelId="demo-simple-select-filled-label"
                                                                  id="demo-simple-select-filled"
                                                                  name="Estado"
                                                                  value={
                                                                        values.Estado
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  error={Boolean(
                                                                        touched.Estado &&
                                                                              errors.Estado
                                                                  )}
                                                                  helperText={
                                                                        touched.Estado &&
                                                                        errors.Estado
                                                                  }
                                                            >
                                                                  <MenuItem
                                                                        value={
                                                                              "AFILIADO"
                                                                        }
                                                                  >
                                                                        AFILIADO
                                                                  </MenuItem>
                                                                  <MenuItem
                                                                        value={
                                                                              "APAGADO"
                                                                        }
                                                                  >
                                                                        APAGADO
                                                                  </MenuItem>
                                                                  <MenuItem
                                                                        value={
                                                                              "EQUIVOCADO"
                                                                        }
                                                                  >
                                                                        EQUIVOCADO
                                                                  </MenuItem>
                                                                  <MenuItem
                                                                        value={
                                                                              "NO LE INTERESA"
                                                                        }
                                                                  >
                                                                        NO LE
                                                                        INTERESA
                                                                  </MenuItem>
                                                                  <MenuItem
                                                                        value={
                                                                              "SIN MASCOTAS"
                                                                        }
                                                                  >
                                                                        SIN
                                                                        MASCOTAS
                                                                  </MenuItem>
                                                            </Select>
                                                      </FormControl>
                                                </DialogContent>
                                                <DialogActions>
                                                      <Grid
                                                            container
                                                            md={12}
                                                            justifyContent="center"
                                                            style={{
                                                                  marginTop:
                                                                        "20px",
                                                            }}
                                                      >
                                                            <Button
                                                                  variant="contained"
                                                                  size="medium"
                                                                  type="submit"
                                                                  style={{
                                                                        marginRight:
                                                                              "20px",
                                                                        color: "white",
                                                                        background:
                                                                              "#800000",
                                                                  }}
                                                            >
                                                                  Modificar
                                                            </Button>
                                                            <Button
                                                                  style={{
                                                                        color: "white",
                                                                        background:
                                                                              "#800000",
                                                                  }}
                                                                  variant="contained"
                                                                  size="medium"
                                                                  type="button"
                                                                  onClick={() =>
                                                                        cerrarModalEstados()
                                                                  }
                                                            >
                                                                  Cancelar
                                                            </Button>
                                                      </Grid>
                                                </DialogActions>
                                          </Form>
                                    )}
                              </Formik>
                        </div>

                        <DialogActions></DialogActions>
                  </Dialog>

                  <Dialog open={openMascotas} onClose={openMascotas}>
                        <div style={{ padding: "5px" }}>
                              <DialogTitle>
                                    <div
                                          style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                          }}
                                    >
                                          <Tooltip
                                                interactive
                                                placement={"top"}
                                                title={"Cerrar"}
                                          >
                                                <IconButton
                                                      aria-label="details"
                                                      style={{
                                                            color: "#ff0000",
                                                      }}
                                                      size={"medium"}
                                                      onClick={() =>
                                                            cerrarModalMascotas()
                                                      }
                                                >
                                                      <CancelRoundedIcon />
                                                </IconButton>
                                          </Tooltip>
                                    </div>
                                    <Typography
                                          variant="h6"
                                          component="h3"
                                          align="center"
                                    >
                                          <b>Mascotas de {data.Nombre}</b>
                                    </Typography>
                              </DialogTitle>
                              <DialogContent>
                                    <Grid
                                          container
                                          justifyContent="center"
                                          mt={2}
                                          md={12}
                                          sm={12}
                                    >
                                          <div class="table-responsive-sm">
                                                <table className="table table-bordered table-hover table-sm table-light">
                                                      <thead>
                                                            <tr
                                                                  style={{
                                                                        background:
                                                                              "#ff0000",
                                                                        color: "white",
                                                                  }}
                                                            >
                                                                  <th colSpan="9">
                                                                        <Typography
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    Listado
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                            </tr>
                                                      </thead>
                                                      <tbody>
                                                            <tr>
                                                                  <th
                                                                        colSpan="1"
                                                                        scope="rowgroup"
                                                                  >
                                                                        <Typography
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    N°
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th
                                                                        colSpan="1"
                                                                        scope="rowgroup"
                                                                  >
                                                                        <Typography
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    Nombre
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th
                                                                        colSpan="1"
                                                                        scope="rowgroup"
                                                                  >
                                                                        <Typography
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    Tipo
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                                  <th
                                                                        colSpan="1"
                                                                        scope="rowgroup"
                                                                  >
                                                                        <Typography
                                                                              style={{
                                                                                    fontSize: "15px",
                                                                              }}
                                                                              component="h2"
                                                                              align="center"
                                                                        >
                                                                              <b>
                                                                                    Sexo
                                                                              </b>
                                                                        </Typography>
                                                                  </th>
                                                            </tr>
                                                            {mascotas.map(
                                                                  (m) => (
                                                                        <tr>
                                                                              <th
                                                                                    colSpan="1"
                                                                                    scope="rowgroup"
                                                                              >
                                                                                    <Typography
                                                                                          style={{
                                                                                                fontSize: "15px",
                                                                                          }}
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                m.No
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th
                                                                                    colSpan="1"
                                                                                    scope="rowgroup"
                                                                              >
                                                                                    <Typography
                                                                                          style={{
                                                                                                fontSize: "15px",
                                                                                          }}
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                m.Nombre
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                              <th
                                                                                    colSpan="1"
                                                                                    scope="rowgroup"
                                                                              >
                                                                                    <Typography
                                                                                          style={{
                                                                                                fontSize: "15px",
                                                                                          }}
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                m.Tipo
                                                                                          }
                                                                                    </Typography>
                                                                              </th>

                                                                              <th
                                                                                    colSpan="1"
                                                                                    scope="rowgroup"
                                                                              >
                                                                                    <Typography
                                                                                          style={{
                                                                                                fontSize: "15px",
                                                                                          }}
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          {
                                                                                                m.Sexo
                                                                                          }
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  )
                                                            )}
                                                      </tbody>
                                                </table>
                                          </div>
                                    </Grid>
                              </DialogContent>
                              <DialogActions></DialogActions>
                        </div>
                  </Dialog>
                  <Grid
                        container
                        sx={{ height: "100vh" }}
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                  >
                        <Grid container md={12} justifyContent="center" mb={2}>
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>Prospectos</b>
                              </Typography>
                        </Grid>
                        <Grid container justifyContent="center" md={12}>
                              <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ justifyContents: "end" }}
                              >
                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Nuevo Prospecto"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "#ff0000",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      (window.location =
                                                            "/prospectos")
                                                }
                                          >
                                                <PersonAddAlt1Icon />
                                          </IconButton>
                                    </Tooltip>
                              </Grid>

                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[
                                                      10, 50, 100,
                                                ]}
                                                labelRowsPerPage={
                                                      "Contratos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={prospectos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Grid>
            </Container>
      );
};

export default GestionProspectos;
