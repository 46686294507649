import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Swal from "sweetalert2";
import { Navigate, useParams } from "react-router-dom";
import * as Yup from "yup";
var mascotas = [];

const FormMascotasProspectos = () => {
      const { cc } = useParams();
      const [visibleTable, setVisibleTable] = useState(false);
      const [id, setId] = useState("");
      const [prospecto, setProspecto] = useState([]);
      

      useEffect(() => {
            traerProspecto();
      }, []);

      const traerProspecto = () => {
            fetch(
                  `https://dejandohuellas.syfacol.com/prospectos/buscarId/${cc}`
            )
                  .then((res) => res.json())
                  .then((res) => setProspecto(res));
      };

      const guardarMascotas = (values, resetForm) => {
            values.IdProspectos = id;
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/prospectos/registroPets",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              text: "Deseas Agregar otra Mascota?",
                              icon: "success",
                              showCancelButton: true,
                              confirmButtonColor: "green",
                              cancelButtonColor: "red",
                              confirmButtonText: "SI",
                              cancelButtonText: "NO",
                        }).then((result) => {
                              if (result.isConfirmed) {
                                    resetForm();
                              } else {
                                    window.location = "/gestprospectos";
                              }
                        })
                  );
      };

      return (
            <Box
                  sx={{
                        bgcolor: "white",
                        height: "100vh",
                        alignContent: "center",
                        justifyContent: "center",
                  }}
            >
                  <Grid
                        container
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                        sx={{ height: "100vh" }}
                  >
                        <Grid
                              container
                              justifyContent="center"
                              xs={10}
                              sm={10}
                              md={7}
                        >
                              <Formik
                                    initialValues={{
                                          Nombre: "",
                                          Tipo: "",
                                          Sexo: "",
                                          IdProspectos: id,
                                    }}
                                    validationSchema={Yup.object().shape({
                                          Nombre: Yup.string()
                                                .matches(
                                                      /^[A-Za-z\sÀ-ÿ]+$/,
                                                      "* Solo se admiten letras"
                                                )
                                                .required("* Requerido"),
                                          Sexo: Yup.string().required(
                                                "* Requerido"
                                          ),
                                          Tipo: Yup.string().required(
                                                "* Requerido"
                                          ),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                          guardarMascotas(values, resetForm);
                                          resetForm();
                                    }}
                              >
                                    {({
                                          errors,
                                          touched,
                                          values,
                                          handleBlur,
                                          handleChange,
                                          setFieldValue,
                                          resetForm,
                                    }) => (
                                          <Form>
                                                <Grid
                                                      container
                                                      spacing={2}
                                                      xs={12}
                                                      sm={12}
                                                      md={12}
                                                      p={5}
                                                >
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <Typography
                                                                  variant="h6"
                                                                  component="h2"
                                                                  align="center"
                                                                  style={{
                                                                        marginTop:
                                                                              "9px",
                                                                        color: "#1b1b1b",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        MASCOTAS
                                                                        DE :{" "}
                                                                        {prospecto.map(
                                                                              (
                                                                                    p
                                                                              ) =>
                                                                                    p.Nombre
                                                                        )}
                                                                  </b>
                                                            </Typography>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h4"
                                                            >
                                                                  <b>
                                                                        *
                                                                        MASCOTAS
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  id="Nombre"
                                                                  name="Nombre"
                                                                  label="Nombre *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Nombre
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  error={Boolean(
                                                                        touched.Nombre &&
                                                                              errors.Nombre
                                                                  )}
                                                                  helperText={
                                                                        touched.Nombre &&
                                                                        errors.Nombre
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Tipo *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Tipo"
                                                                        value={
                                                                              values.Tipo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Tipo &&
                                                                                    errors.Tipo
                                                                        )}
                                                                        helperText={
                                                                              touched.Tipo &&
                                                                              errors.Tipo
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "PERRO"
                                                                              }
                                                                        >
                                                                              PERRO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "GATO"
                                                                              }
                                                                        >
                                                                              GATO
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <FormControl
                                                                  variant="filled"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Sexo *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Sexo"
                                                                        value={
                                                                              values.Sexo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Sexo &&
                                                                                    errors.Sexo
                                                                        )}
                                                                        helperText={
                                                                              touched.Sexo &&
                                                                              errors.Sexo
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "MACHO"
                                                                              }
                                                                        >
                                                                              MACHO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "HEMBRA"
                                                                              }
                                                                        >
                                                                              HEMBRA
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            md={12}
                                                            xs={12}
                                                            sm={12}
                                                      >
                                                            <table className="table table-bordered  table-hover table-sm  mt-5">
                                                                  <thead>
                                                                        <tr
                                                                              style={{
                                                                                    color: "white",
                                                                                    background:
                                                                                          "#ff0000",
                                                                              }}
                                                                        >
                                                                              <th colSpan="5">
                                                                                    <Typography
                                                                                          variant="subtitle2"
                                                                                          component="h2"
                                                                                          align="center"
                                                                                    >
                                                                                          <b>
                                                                                                INFORMACIÓN
                                                                                                DEL
                                                                                                TITULAR
                                                                                          </b>
                                                                                    </Typography>
                                                                              </th>
                                                                        </tr>
                                                                  </thead>
                                                                  {prospecto.map(
                                                                        (
                                                                              con
                                                                        ) => (
                                                                              <tbody>
                                                                                    <tr>
                                                                                          <th
                                                                                                colSpan="3"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            NOMBRES
                                                                                                            Y
                                                                                                            APELLIDOS:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Nombre
                                                                                                      }
                                                                                                      {setId(
                                                                                                            con.Id
                                                                                                      )}
                                                                                                </Typography>
                                                                                          </th>
                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            C.C{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Cedula
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>

                                                                                          <th
                                                                                                colSpan="1"
                                                                                                scope="rowgroup"
                                                                                          >
                                                                                                <Typography
                                                                                                      variant="subtitle2"
                                                                                                      component="h2"
                                                                                                >
                                                                                                      <b>
                                                                                                            TELEFÓNO:{" "}
                                                                                                      </b>
                                                                                                      {
                                                                                                            con.Telefono
                                                                                                      }
                                                                                                </Typography>
                                                                                          </th>
                                                                                    </tr>
                                                                              </tbody>
                                                                        )
                                                                  )}
                                                            </table>
                                                      </Grid>
                                                      {visibleTable && (
                                                            <Grid item md={12}>
                                                                  <table className="table table-bordered table-sm  table-hover">
                                                                        <thead>
                                                                              <tr
                                                                                    style={{
                                                                                          color: "white",
                                                                                          background:
                                                                                                "#ff0000",
                                                                                    }}
                                                                              >
                                                                                    <th colSpan="6">
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      MASCOTAS
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              <tr>
                                                                                    <th
                                                                                          colSpan="2"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      NOMBRE
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                                    <th
                                                                                          colSpan="2"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      TIPO
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>

                                                                                    <th
                                                                                          colSpan="2"
                                                                                          scope="rowgroup"
                                                                                    >
                                                                                          <Typography
                                                                                                variant="subtitle2"
                                                                                                component="h2"
                                                                                                align="center"
                                                                                          >
                                                                                                <b>
                                                                                                      SEXO
                                                                                                </b>
                                                                                          </Typography>
                                                                                    </th>
                                                                              </tr>
                                                                              {mascotas.map(
                                                                                    (
                                                                                          m
                                                                                    ) => (
                                                                                          <tr>
                                                                                                <th
                                                                                                      colSpan="2"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Nombre
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                                <th
                                                                                                      colSpan="2"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Tipo
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>

                                                                                                <th
                                                                                                      colSpan="2"
                                                                                                      scope="rowgroup"
                                                                                                >
                                                                                                      <Typography
                                                                                                            variant="subtitle2"
                                                                                                            component="h2"
                                                                                                            align="center"
                                                                                                      >
                                                                                                            {
                                                                                                                  m.Sexo
                                                                                                            }
                                                                                                      </Typography>
                                                                                                </th>
                                                                                          </tr>
                                                                                    )
                                                                              )}
                                                                        </tbody>
                                                                  </table>
                                                            </Grid>
                                                      )}

                                                      <Grid
                                                            container
                                                            md={12}
                                                            justifyContent="center"
                                                            style={{
                                                                  marginTop:
                                                                        "15px",
                                                                  marginBottom:
                                                                        "15px",
                                                            }}
                                                      >
                                                            <Button
                                                                  style={{
                                                                        color: "white",
                                                                        height: "40px",
                                                                        background:
                                                                              "#ff0000",
                                                                  }}
                                                                  variant="contained"
                                                                  size="small"
                                                                  type="submit"
                                                            >
                                                                  <Typography variant="h6">
                                                                        Registrar
                                                                  </Typography>
                                                            </Button>
                                                      </Grid>
                                                </Grid>
                                          </Form>
                                    )}
                              </Formik>
                        </Grid>
                  </Grid>
            </Box>
      ) 
};
export default FormMascotasProspectos;
