import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
const fecha = new Date();

const FormProspectos = () => {
      

      const guardarProspecto = (values) => {
            values.Fecha =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();

            values.FechaActualizada =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();

            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/prospectos/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              icon: "success",
                              confirmButtonText: "Listo",
                              confirmButtonColor: "#4c4",
                        }).then((res) => {
                              if (res.isConfirmed) {
                                    window.location = `/prospMascotas/${values.Cedula}`;
                              }
                        })
                  );
      };

      return  (
            <Container>
                  <Grid
                        container
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                        sx={{ height: "100vh" }}
                  >
                        <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              xs={10}
                              sm={10}
                              md={7}
                        >
                              <Formik
                                    initialValues={{
                                          Fecha: "",
                                          FechaActualizada: "",
                                          Nombre: "",
                                          Cedula: "",
                                          Telefono: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                          Nombre: Yup.string()
                                                .matches(
                                                      /^[A-Za-z\sÀ-ÿ]+$/,
                                                      "* Solo se admiten letras"
                                                )
                                                .required("* Requerido"),

                                          Cedula: Yup.number(
                                                "* Solo se admiten números"
                                          ).required("* Requerido"),

                                          Telefono: Yup.number(
                                                "* Solo se admiten números"
                                          ).required("* Requerido"),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                          guardarProspecto(values);
                                          resetForm();
                                    }}
                              >
                                    {({
                                          errors,
                                          touched,
                                          values,
                                          handleBlur,
                                          handleChange,
                                          setFieldValue,
                                    }) => (
                                          <Form>
                                                <Grid
                                                      container
                                                      spacing={2}
                                                      md={12}
                                                      sm={12}
                                                      justifyContent="center"
                                                      p={5}
                                                >
                                                      <Grid
                                                            item
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <Typography
                                                                  variant="h6"
                                                                  component="h2"
                                                                  align="center"
                                                                  style={{
                                                                        marginTop:
                                                                              "12px",
                                                                        color: "#1b1b1b",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        REGISTRO
                                                                        DE
                                                                        PROSPECTOS
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                      >
                                                            <Typography
                                                                  variant="subtitle1"
                                                                  component="h4"
                                                            >
                                                                  <b>
                                                                        * DATOS
                                                                        DEL
                                                                        PROSPECTO
                                                                  </b>
                                                            </Typography>
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Cedula"
                                                                  label="Identificación *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Cedula
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  error={Boolean(
                                                                        touched.Cedula &&
                                                                              errors.Cedula
                                                                  )}
                                                                  helperText={
                                                                        touched.Cedula &&
                                                                        errors.Cedula
                                                                  }
                                                            />
                                                      </Grid>
                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Nombre"
                                                                  label="Nombre y Apellidos *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Nombre
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  error={Boolean(
                                                                        touched.Nombre &&
                                                                              errors.Nombre
                                                                  )}
                                                                  helperText={
                                                                        touched.Nombre &&
                                                                        errors.Nombre
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            justifyContent="center"
                                                      >
                                                            <TextField
                                                                  name="Telefono"
                                                                  label="Celular *"
                                                                  fullWidth
                                                                  margin="none"
                                                                  variant="filled"
                                                                  type="text"
                                                                  size="small"
                                                                  value={
                                                                        values.Telefono
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  error={Boolean(
                                                                        touched.Telefono &&
                                                                              errors.Telefono
                                                                  )}
                                                                  helperText={
                                                                        touched.Telefono &&
                                                                        errors.Telefono
                                                                  }
                                                            />
                                                      </Grid>

                                                      <Grid
                                                            container
                                                            md={10}
                                                            justifyContent="center"
                                                            style={{
                                                                  marginTop:
                                                                        "15px",
                                                                  marginBottom:
                                                                        "15px",
                                                            }}
                                                      >
                                                            <Button
                                                                  style={{
                                                                        color: "white",
                                                                        height: "40px",
                                                                        background:
                                                                              "#ff0000",
                                                                  }}
                                                                  variant="contained"
                                                                  size="small"
                                                                  type="submit"
                                                            >
                                                                  <Typography variant="h6">
                                                                        Registrar
                                                                  </Typography>
                                                            </Button>
                                                      </Grid>
                                                </Grid>
                                          </Form>
                                    )}
                              </Formik>
                        </Grid>
                  </Grid>
            </Container>
      ) 
};
export default FormProspectos;
