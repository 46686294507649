import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataGrid /*esES*/ } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import { Container, Grid, Typography, Button, Paper } from "@mui/material";
import Swal from "sweetalert2";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Stack from "@mui/material/Stack";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./footer/Footer";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { ExportToExcel } from "./ExportToExcel";
import { useSelector } from "react-redux";

const LaborCobros = () => {
      const token = sessionStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_URL;
      const navigate = useNavigate();
      const fecha = new Date();
      const fecActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate();

      const [fechaActual1, setFechaActual1] = useState(fecActual);
      const [fechaActual2, setFechaActual2] = useState(fecActual);
      const [fec1File, setFec1File] = useState([]);
      const [fec2File, setFec2File] = useState([]);
      const [gastos, setGastos] = useState([]);
      const [pagos, setPagos] = useState([]);
      const [pagosCopy, setPagosCopy] = useState([]);
      const [gastosCopy, setGastosCopy] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [rowsPerPage1, setRowsPerPage1] = useState(5);
      const [size, setSize] = useState(0);
      const [size1, setSize1] = useState(0);
      const [data, setData] = useState([]);
      const [openGasto, setOpenGasto] = useState(false);
      const [openFilter, setOpenFilter] = useState(false);
      const [openEditarGasto, setOpenEditarGasto] = useState(false);
      const [openEditarPago, setOpenEditarPago] = useState(false);
      const [totalPagos, setTotalPagos] = useState("");
      const [totalGastos, setTotalGastos] = useState("");
      const [totalPagosEfectivo, setTotalPagosEfectivo] = useState("");
      const [totalPagosElectronicos, setTotalPagosElectronicos] = useState("");
      const totalneto = totalPagos - totalGastos;
      const [diasCobrados, setDiasCobrados] = useState([]);
      const [visible, setVisible] = useState(true);
      const theme = createTheme();
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );

      theme.typography.h5 = {
            fontSize: "1.5rem",
            "@media (max-width:600px)": {
                  fontSize: "1rem",
            },
      };

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerTotalPagos();
                  traerTotalGastos();
                  traerTotalPagosEfectivo();
                  traerTotalPagosElectronicos();
                  traerTotalDiasCobrados();
                  traerTodosPagos();
                  traerTodosGastos();
                  setActualizar(false);
            }
      }, [actualizar, selectedAgencia]);
      const meses = [
            {
                  No: 1,
                  Mes: "1 MES",
            },
            {
                  No: 2,
                  Mes: "2 MESES",
            },
            {
                  No: 3,
                  Mes: "3 MESES",
            },
            {
                  No: 4,
                  Mes: "4 MESES",
            },
            {
                  No: 5,
                  Mes: "5 MESES",
            },
            {
                  No: 6,
                  Mes: "6 MESES",
            },
            {
                  No: 7,
                  Mes: "7 MESES",
            },
            {
                  No: 8,
                  Mes: "8 MESES",
            },
            {
                  No: 9,
                  Mes: "9 MESES",
            },
            {
                  No: 10,
                  Mes: "10 MESES",
            },
            {
                  No: 11,
                  Mes: "11 MESES",
            },
            {
                  No: 12,
                  Mes: "12 MESES",
            },
      ];

      const columns = [
            {
                  field: "Fecha",
                  headerName: "Fecha",
                  width: 200,
                  headerAlign: "center",
                  editable: true,
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.Fecha}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Detalle",
                  headerName: "Detalle",
                  width: 370,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Valor",
                  headerName: "Valor",
                  width: 150,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{formatterPeso.format(params.row.Valor)}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Empleado",
                  headerName: "Empleado",
                  width: 250,
                  headerAlign: "center",
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 160,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Modificar Gasto"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEditar(params.row)
                                          }
                                    >
                                          <EditIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const columns1 = [
            {
                  field: "Fecha",
                  headerName: "Fecha",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.Fecha}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Id",
                  headerName: "N° Recibo",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <b>{params.row.Id}</b>
                        </div>
                  ),
            },
            {
                  field: "Detalle",
                  headerName: "Detalle",
                  width: 280,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Valor",
                  headerName: "Valor",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{formatterPeso.format(params.row.Valor)}</b>
                        </Stack>
                  ),
            },
            {
                  field: "Nombres",
                  headerName: "Cliente",
                  width: 250,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "ElaboradoPor",
                  headerName: "Empleado",
                  width: 210,
                  headerAlign: "center",
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 160,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Modificar Pago"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEditarPago(params.row)
                                          }
                                    >
                                          <EditIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Comprobante"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                (window.location = `/comprobanteindividual/${params.row.Id}`)
                                          }
                                    >
                                          <PictureAsPdfIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const monthNames = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
      ];

      const getLongMonthName = (date) => {
            //OBTIENE EL NOMBRE LARGO DEL MES
            return monthNames[date.getMonth()];
      };

      const guardarGastos = (values) => {
            values.Fecha =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch(baseUrl + "/gastos/registro", requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            setActualizar(true);
            setData("");
            setOpenGasto(!openGasto);
      };

      const editarGastos = (values) => {
            values.Fecha =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch(baseUrl + `/gastos/modificar/${data.Id}`, requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            setActualizar(true);
            setData("");
            setOpenEditarGasto(!openEditarGasto);
      };

      const abrirModalEditar = (props) => {
            setData(props);
            setOpenEditarGasto(!openEditarGasto);
      };

      const cerrarModalEditar = () => {
            setOpenEditarGasto(!openEditarGasto);
            setData("");
      };

      const abrirModalEditarPago = (props) => {
            setData(props);
            setOpenEditarPago(!openEditarPago);
      };

      const cerrarModalPago = () => {
            setOpenEditarPago(!openEditarPago);
            setData("");
      };

      const abrirModalGastos = () => {
            setOpenGasto(!openGasto);
      };

      const cerrarModalGastos = () => {
            setOpenGasto(!openGasto);
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const traerTodosPagos = () => {
            fetch(
                  baseUrl +
                        `/pagos/todosPagosHoy/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setSize1(res.length);
                        let array = res.map((item) => ({
                              Fecha: item.Fecha,
                              Id: item.Id,
                              Detalle: item.Detalle,
                              Valor: item.Valor,
                              Nombres: item.Nombres,
                              ElaboradoPor: item.ElaboradoPor,
                        }));

                        setPagosCopy(array);
                        setPagos(res);
                  });
      };

      const traerTodosGastos = () => {
            fetch(
                  baseUrl +
                        `/gastos/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        let array = res.map((item) => ({
                              Fecha: item.Fecha,
                              Detalle: item.Detalle,
                              Valor: item.Valor,
                              Empleado: item.Empleado,
                        }));

                        setGastosCopy(array);
                        setGastos(res);
                  });
      };

      const traerTotalPagosEfectivo = () => {
            fetch(
                  baseUrl +
                        `/pagos/pagosEfectivo/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalPagosEfectivo(r.PAGOS_EFECTIVO));
                  });
      };

      const traerTotalPagosElectronicos = () => {
            fetch(
                  baseUrl +
                        `/pagos/pagosElectronicos/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) =>
                              setTotalPagosElectronicos(r.PAGOS_ELECTRONICOS)
                        );
                  });
      };

      const traerTotalPagos = () => {
            fetch(
                  baseUrl +
                        `/pagos/totalPagosPorRango/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalPagos(r.TotalCobros));
                  });
      };

      const traerTotalGastos = () => {
            fetch(
                  baseUrl +
                        `/pagos/totalGastosPorRango/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalGastos(r.TotalGastos));
                  });
      };

      const traerTotalDiasCobrados = () => {
            fetch(
                  baseUrl +
                        `/pagos/totalPagosPorDiaMes/${fechaActual1}/${fechaActual2}/${selectedAgencia}`
            )
                  .then((res) => res.json())
                  .then((res) => setDiasCobrados(res));
      };

      const filtrarPorFechas = (values) => {
            let fec1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();

            let fec2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            setFec1File(values.Fecha1);
            setFec2File(values.Fecha2);
            setVisible(false);
            setFechaActual1(fec1);
            setFechaActual2(fec2);
            setActualizar(!actualizar);
            setOpenFilter(!openFilter);
      };

      const editarPago = (values) => {
            values.Fecha =
                  fecha.getFullYear() +
                  "-" +
                  (fecha.getMonth() + 1) +
                  "-" +
                  fecha.getDate() +
                  " " +
                  fecha.getHours() +
                  ":" +
                  fecha.getMinutes() +
                  ":" +
                  fecha.getSeconds();

            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };

            fetch(baseUrl + `/pagos/modificar/${data.Id}`, requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 2000,
                        })
                  );

            setActualizar(true);
            setData("");
            setOpenEditarPago(!openEditarPago);
      };
      const actualizacion = () => {
            setVisible(true);
            setFechaActual1(fecActual);
            setFechaActual2(fecActual);
            setActualizar(!actualizar);
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Dialog open={openFilter} onClose={openFilter}>
                              <div style={{ padding: "12px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha1: null,
                                                Fecha2: null,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Fecha1: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                                Fecha2: Yup.date()
                                                      .nullable()
                                                      .required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                filtrarPorFechas(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "-15px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Filtrar
                                                                        por
                                                                        Fechas
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <div
                                                                  style={{
                                                                        width: "100%",
                                                                  }}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="medium"
                                                                              name="Fecha1"
                                                                              format="dd/MM/yyyy"
                                                                              margin="normal"
                                                                              label="Fecha 1 *"
                                                                              fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha1
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Fecha1 &&
                                                                                          errors.Fecha1
                                                                              )}
                                                                              helperText={
                                                                                    touched.Fecha1 &&
                                                                                    errors.Fecha1
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha1",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </div>
                                                            <div
                                                                  style={{
                                                                        width: "100%",
                                                                  }}
                                                            >
                                                                  <MuiPickersUtilsProvider
                                                                        utils={
                                                                              DateFnsUtils
                                                                        }
                                                                        locale={
                                                                              esLocale
                                                                        }
                                                                  >
                                                                        <KeyboardDatePicker
                                                                              size="medium"
                                                                              name="Fecha2"
                                                                              format="dd/MM/yyyy"
                                                                              //margin="normal"
                                                                              label="Fecha 2 *"
                                                                              fullWidth
                                                                              // inputVariant="filled"
                                                                              value={
                                                                                    values.Fecha2
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Fecha2 &&
                                                                                          errors.Fecha2
                                                                              )}
                                                                              helperText={
                                                                                    touched.Fecha2 &&
                                                                                    errors.Fecha2
                                                                              }
                                                                              onChange={(
                                                                                    value
                                                                              ) =>
                                                                                    setFieldValue(
                                                                                          "Fecha2",
                                                                                          value
                                                                                    )
                                                                              }
                                                                              KeyboardButtonProps={{
                                                                                    "aria-label":
                                                                                          "change date",
                                                                              }}
                                                                        />
                                                                  </MuiPickersUtilsProvider>
                                                            </div>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  style={{
                                                                        marginTop:
                                                                              "8px",
                                                                        marginBottom:
                                                                              "8px",
                                                                  }}
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Filtrar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={() =>
                                                                              setOpenFilter(
                                                                                    !openFilter
                                                                              )
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog open={openGasto} onClose={openGasto}>
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha: "",
                                                Detalle: "",
                                                Valor: "",
                                                Empleado:    sessionStorage.getItem(
                                                      "user"
                                                ),
                                                IdAgencia: selectedAgencia,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Detalle: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Valor: Yup.number(
                                                      "* Solo se admiten números"
                                                )
                                                      .positive(
                                                            "* No se aceptan valores negativos"
                                                      )
                                                      .required("* Requerido"),
                                                Empleado: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                guardarGastos(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "2px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Registrar
                                                                        Gasto
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <TextField
                                                                  name="Detalle"
                                                                  label="Detalle *"
                                                                  multiline
                                                                  maxRows={4}
                                                                  error={Boolean(
                                                                        touched.Detalle &&
                                                                              errors.Detalle
                                                                  )}
                                                                  helperText={
                                                                        touched.Detalle &&
                                                                        errors.Detalle
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Detalle
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <TextField
                                                                  name="Valor"
                                                                  label="Valor *"
                                                                  error={Boolean(
                                                                        touched.Valor &&
                                                                              errors.Valor
                                                                  )}
                                                                  helperText={
                                                                        touched.Valor &&
                                                                        errors.Valor
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Valor
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Empleado
                                                                        *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Empleado"
                                                                        value={
                                                                              values.Empleado
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Empleado &&
                                                                                    errors.Empleado
                                                                        )}
                                                                        helperText={
                                                                              touched.Empleado &&
                                                                              errors.Empleado
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "DIANA MARCELA MARIN BONILLA"
                                                                              }
                                                                        >
                                                                              DIANA
                                                                              MARCELA
                                                                              MARIN
                                                                              BONILLA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ROLANDO EDUFAMIR ESPAÑA LOSADA"
                                                                              }
                                                                        >
                                                                              ROLANDO
                                                                              EDUFAMIR
                                                                              ESPAÑA
                                                                              LOSADA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ROLAND ESPAÑA RODRIGUEZ"
                                                                              }
                                                                        >
                                                                              ROLAND
                                                                              ESPAÑA
                                                                              RODRIGUEZ
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "JAMES GUERRERO"
                                                                              }
                                                                        >
                                                                              JAMES
                                                                              GUERRERO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "HELLEN TATIANA CASTRO MARIN"
                                                                              }
                                                                        >
                                                                              HELLEN
                                                                              TATIANA
                                                                              CASTRO
                                                                              MARIN
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Registrar
                                                                  </Button>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalGastos()
                                                                        }
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog
                              open={openEditarGasto}
                              onClose={openEditarGasto}
                        >
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha: "",
                                                Detalle: data.Detalle,
                                                Valor: data.Valor,
                                                Empleado: data.Empleado,
                                                IdAgencia: selectedAgencia,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Detalle: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Valor: Yup.number(
                                                      "* Solo se admiten números"
                                                )
                                                      .positive(
                                                            "* No se aceptan valores negativos"
                                                      )
                                                      .required("* Requerido"),
                                                Empleado: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                editarGastos(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "2px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Registrar
                                                                        Gasto
                                                                  </b>
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <TextField
                                                                  name="Detalle"
                                                                  label="Detalle *"
                                                                  multiline
                                                                  maxRows={4}
                                                                  error={Boolean(
                                                                        touched.Detalle &&
                                                                              errors.Detalle
                                                                  )}
                                                                  helperText={
                                                                        touched.Detalle &&
                                                                        errors.Detalle
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Detalle
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <TextField
                                                                  name="Valor"
                                                                  label="Valor *"
                                                                  error={Boolean(
                                                                        touched.Valor &&
                                                                              errors.Valor
                                                                  )}
                                                                  helperText={
                                                                        touched.Valor &&
                                                                        errors.Valor
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Valor
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Empleado
                                                                        *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Empleado"
                                                                        value={
                                                                              values.Empleado
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Empleado &&
                                                                                    errors.Empleado
                                                                        )}
                                                                        helperText={
                                                                              touched.Empleado &&
                                                                              errors.Empleado
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "DIANA MARCELA MARIN BONILLA"
                                                                              }
                                                                        >
                                                                              DIANA
                                                                              MARCELA
                                                                              MARIN
                                                                              BONILLA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ROLANDO EDUFAMIR ESPAÑA LOSADA"
                                                                              }
                                                                        >
                                                                              ROLANDO
                                                                              EDUFAMIR
                                                                              ESPAÑA
                                                                              LOSADA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "ROLAND ESPAÑA RODRIGUEZ"
                                                                              }
                                                                        >
                                                                              ROLAND
                                                                              ESPAÑA
                                                                              RODRIGUEZ
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "JAMES GUERRERO"
                                                                              }
                                                                        >
                                                                              JAMES
                                                                              GUERRERO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "HELLEN TATIANA CASTRO MARIN"
                                                                              }
                                                                        >
                                                                              HELLEN
                                                                              TATIANA
                                                                              CASTRO
                                                                              MARIN
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Modificar
                                                                  </Button>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="medium"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalEditar()
                                                                        }
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Dialog open={openEditarPago} onClose={openEditarPago}>
                              <div style={{ padding: "20px" }}>
                                    <Formik
                                          initialValues={{
                                                Fecha: "",
                                                Meses: data.Meses,
                                                Detalle: data.Detalle,
                                                Valor: data.Valor,
                                                MedioPago: data.MedioPago,
                                                ElaboradoPor:
                                                      sessionStorage.getItem(
                                                            "user"
                                                      ),
                                                IdContrato: data.NoContrato,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Detalle: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Valor: Yup.number(
                                                      "* Solo se admiten números"
                                                )
                                                      .positive(
                                                            "* No se aceptan valores negativos"
                                                      )
                                                      .required("* Requerido"),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                editarPago(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h3"
                                                                  align="center"
                                                                  style={{
                                                                        marginBottom:
                                                                              "10px",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Modificar
                                                                        Pago
                                                                  </b>
                                                            </Typography>
                                                            <Typography
                                                                  variant="h6"
                                                                  component="h3"
                                                                  style={{
                                                                        marginBottom:
                                                                              "-20px",
                                                                        color: "#800000",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Cliente:
                                                                  </b>{" "}
                                                                  {data.Nombres}
                                                            </Typography>
                                                      </DialogTitle>
                                                      <DialogContent>
                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Meses
                                                                        que Paga
                                                                        *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Meses"
                                                                        value={
                                                                              data.Meses
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Meses &&
                                                                                    errors.Meses
                                                                        )}
                                                                        helperText={
                                                                              touched.Meses &&
                                                                              errors.Meses
                                                                        }
                                                                  >
                                                                        {meses.map(
                                                                              (
                                                                                    m
                                                                              ) => (
                                                                                    <MenuItem
                                                                                          value={
                                                                                                m.No
                                                                                          }
                                                                                    >
                                                                                          {
                                                                                                m.Mes
                                                                                          }
                                                                                    </MenuItem>
                                                                              )
                                                                        )}
                                                                  </Select>
                                                            </FormControl>

                                                            <TextField
                                                                  name="Detalle"
                                                                  label="Detalle *"
                                                                  multiline
                                                                  maxRows={4}
                                                                  error={Boolean(
                                                                        touched.Detalle &&
                                                                              errors.Detalle
                                                                  )}
                                                                  helperText={
                                                                        touched.Detalle &&
                                                                        errors.Detalle
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  value={
                                                                        values.Detalle
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />

                                                            <TextField
                                                                  name="Valor"
                                                                  label="Valor *"
                                                                  error={Boolean(
                                                                        touched.Valor &&
                                                                              errors.Valor
                                                                  )}
                                                                  helperText={
                                                                        touched.Valor &&
                                                                        errors.Valor
                                                                  }
                                                                  fullWidth
                                                                  margin="normal"
                                                                  // variant="standard"
                                                                  value={
                                                                        values.Valor
                                                                  }
                                                                  onBlur={
                                                                        handleBlur
                                                                  }
                                                                  onChange={
                                                                        handleChange
                                                                  }
                                                                  type="text"
                                                                  // size='small'
                                                            />
                                                            <FormControl
                                                                  fullWidth
                                                                  size="small"
                                                                  margin="normal"
                                                            >
                                                                  <InputLabel id="demo-simple-select-filled-label">
                                                                        Medio de
                                                                        Pago *
                                                                  </InputLabel>
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="MedioPago"
                                                                        value={
                                                                              values.MedioPago
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.MedioPago &&
                                                                                    errors.MedioPago
                                                                        )}
                                                                        helperText={
                                                                              touched.MedioPago &&
                                                                              errors.MedioPago
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "AHORRO A LA  MANO"
                                                                              }
                                                                        >
                                                                              AHORRO
                                                                              A
                                                                              LA
                                                                              MANO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "BANCOLOMBIA"
                                                                              }
                                                                        >
                                                                              BANCOLOMBIA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "DAVIPLATA"
                                                                              }
                                                                        >
                                                                              DAVIPLATA
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "EFECTIVO"
                                                                              }
                                                                        >
                                                                              EFECTIVO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "NEQUI"
                                                                              }
                                                                        >
                                                                              NEQUI
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  mt={2}
                                                                  mb={2}
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "20px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Modificar
                                                                  </Button>
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalPago()
                                                                        }
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>
                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{
                                          marginTop: "12px",
                                    }}
                              >
                                    {visible ? (
                                          <b>
                                                Labor de Cobros Hoy{" "}
                                                {fecha.getDate() +
                                                      "/" +
                                                      getLongMonthName(fecha) +
                                                      "/" +
                                                      fecha.getFullYear()}
                                          </b>
                                    ) : (
                                          <b>
                                                Labor de Cobros del{" "}
                                                {fec1File.getDate() +
                                                      "/" +
                                                      getLongMonthName(
                                                            fec1File
                                                      ) +
                                                      "/" +
                                                      fec1File.getFullYear()}{" "}
                                                al{" "}
                                                {fec2File.getDate() +
                                                      "/" +
                                                      getLongMonthName(
                                                            fec2File
                                                      ) +
                                                      "/" +
                                                      fec2File.getFullYear()}
                                          </b>
                                    )}
                              </Typography>
                        </Grid>
                        <Grid container md={12} style={{ marginTop: "20px" }}>
                              <Grid item sm={4} xs={4} md={4}>
                                    <table className="table table-bordered table-sm ">
                                          <thead>
                                                <tr
                                                      style={{
                                                            background:
                                                                  "#ff0000",
                                                            color: "white",
                                                      }}
                                                >
                                                      <th colSpan="4">
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  align="center"
                                                            >
                                                                  <b>
                                                                        INFORMACIÓN
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                          </thead>

                                          <tbody>
                                                <tr>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        PAGOS
                                                                        POR
                                                                        EFECTIVO
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        {formatterPeso.format(
                                                                              totalPagosEfectivo
                                                                        )}
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                                <tr>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        PAGOS
                                                                        ELECTRÓNICOS
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        {formatterPeso.format(
                                                                              totalPagosElectronicos
                                                                        )}
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                                <tr>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>GASTOS</b>
                                                            </Typography>
                                                      </th>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        {formatterPeso.format(
                                                                              totalGastos
                                                                        )}
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                                <tr>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>COBROS</b>
                                                            </Typography>
                                                      </th>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        {formatterPeso.format(
                                                                              totalPagos
                                                                        )}
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                                <tr>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        DIAS
                                                                        COBRADOS
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                      <th
                                                            colSpan="2"
                                                            scope="rowgroup"
                                                      >
                                                            <Typography
                                                                  variant="subtitle2"
                                                                  component="h2"
                                                                  align="start"
                                                            >
                                                                  <b>
                                                                        {
                                                                              diasCobrados.length
                                                                        }
                                                                  </b>
                                                            </Typography>
                                                      </th>
                                                </tr>
                                          </tbody>
                                    </table>
                              </Grid>

                              <Grid
                                    item
                                    sm={8}
                                    xs={8}
                                    md={8}
                                    style={{
                                          display: "flex",
                                          justifyContent: "end",
                                    }}
                              >
                                    <ThemeProvider theme={theme}>
                                          <Typography variant="h5">
                                                <b
                                                      style={{
                                                            background:
                                                                  "#ff0000",
                                                            padding: "5px",
                                                            borderRadius:
                                                                  "10px",
                                                            color: "white",
                                                      }}
                                                >
                                                      TOTAL:
                                                      {formatterPeso.format(
                                                            totalneto
                                                      )}
                                                </b>
                                          </Typography>
                                    </ThemeProvider>
                              </Grid>
                              <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    container
                                    justifyContent="start"
                              >
                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Nuevo Gasto"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      abrirModalGastos()
                                                }
                                          >
                                                <AddCircleIcon />
                                          </IconButton>
                                    </Tooltip>

                                    {/*   <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Reporte"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      navigate(
                                                            "/comprobantediario"
                                                      )
                                                }
                                          >
                                                <AssignmentIcon />
                                          </IconButton>
                                    </Tooltip>*/}
                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Filtrar Por Fechas"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() =>
                                                      setOpenFilter(!openFilter)
                                                }
                                          >
                                                <CalendarMonthIcon />
                                          </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                          interactive
                                          placement={"top"}
                                          title={"Actualizar"}
                                    >
                                          <IconButton
                                                aria-label="details"
                                                style={{
                                                      color: "white",
                                                      background: "#ff0000",
                                                      marginRight: "10px",
                                                }}
                                                size={"medium"}
                                                onClick={() => actualizacion()}
                                          >
                                                <PublishedWithChangesIcon />
                                          </IconButton>
                                    </Tooltip>
                                    <ExportToExcel
                                          pagos={pagosCopy}
                                          gastos={gastosCopy}
                                          fileName={`Cobros y Gastos
                                                ${
                                                      visible
                                                            ? fecha.getDate() +
                                                              "/" +
                                                              (fecha.getMonth() +
                                                                    1) +
                                                              "/" +
                                                              fecha.getFullYear()
                                                            : fec1File.getDate() +
                                                              "/" +
                                                              (fec1File.getMonth() +
                                                                    1) +
                                                              "/" +
                                                              fec1File.getFullYear() +
                                                              " - " +
                                                              fec2File.getDate() +
                                                              "/" +
                                                              (fec2File.getMonth() +
                                                                    1) +
                                                              "/" +
                                                              fec2File.getFullYear()
                                                }`}
                                    />
                              </Grid>
                        </Grid>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid item xs={12} md={12}>
                                    <Typography
                                          variant="subtitle1"
                                          align="start"
                                          style={{ marginTop: "20px" }}
                                    >
                                          <b>📌 Lista de Gastos:</b>
                                    </Typography>
                              </Grid>
                              <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    style={{ marginTop: "10px" }}
                              >
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Contratos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={gastos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                    <Typography
                                          variant="subtitle1"
                                          align="start"
                                          style={{ marginTop: "15px" }}
                                    >
                                          <b>📌 Lista de Pagos:</b>
                                    </Typography>
                              </Grid>
                              <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{ marginTop: "5px" }}
                              >
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage1}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage1(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Pagos por pagina"
                                                }
                                                rowCount={size1}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns1}
                                                rows={pagos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default LaborCobros;
