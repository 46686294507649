import React, { useState, useEffect } from "react";
import {
      List,
      ListItem,
      ListItemAvatar,
      Avatar,
      ListItemText,
      Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import io from "socket.io-client";

const ListaUsuarioConectados = () => {
      const socket = io("http://localhost:9000"); // Reemplaza con la URL de tu servidor
      const [conetUsuarios, setUsuarioConect] = useState([]);

      useEffect(() => {
            // Escuchar evento "activeUsers" del servidor
            socket.on("activeUsers", (users) => {
                  console.log(users);
            });

            return () => {
                  // Desconectar el socket cuando el componente se desmonta
                  socket.disconnect();
            };
      }, [socket]);

      return (
            <List
                  sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                  }}
            >
                  {conetUsuarios.map((user) => (
                        <ListItem key={user.Id}>
                              <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: green[500] }}>
                                          {"U"}
                                    </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                    primary={user.User}
                                    secondary={user.Agencia}
                              />
                        </ListItem>
                  ))}
                  {conetUsuarios.length === 0 && (
                        <Typography
                              sx={{ p: 2 }}
                              variant="body2"
                              color="text.secondary"
                        >
                              No hay usuarios conectados.
                        </Typography>
                  )}
            </List>
      );
};

export default ListaUsuarioConectados;
