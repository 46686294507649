import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Swal from "sweetalert2";
import ListSubheader from "@material-ui/core/ListSubheader";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";
import { useSelector } from "react-redux";

const FormServicioParticular = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const fecha = new Date();
      const fechaActual =
            fecha.getFullYear() +
            "-" +
            (fecha.getMonth() + 1) +
            "-" +
            fecha.getDate();
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );

      useEffect(() => {
            if (!token) {
                  navigate("/");
            }
      }, [selectedAgencia]);
      const guardarCliente = (cliente) => {
            const obj = {
                  FecInicial: fechaActual,
                  FecRenovacion: fechaActual,
                  Nombres: cliente.Nombres,
                  Cedula: cliente.Cedula,
                  Direccion: cliente.Direccion,
                  Telefono: cliente.Telefono,
                  formaPago: "CONTADO",
                  EstadoPago: "PARTICULAR",
                  Observaciones: "SERVICIO DE ENTIERRO PARTICULAR",
                  valorTotal: cliente.Valor,
                  valorCuotas: cliente.Valor,
                  ProximoPago: fechaActual,
                  CopyProximoPago: fechaActual,
                  IdUser: sessionStorage.getItem("idUser"),
                  IdAgencia: selectedAgencia,
            };

            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const guardar = (values) => {
            guardarCliente(values);
            guardarMascotas(values);
      };

      const guardarMascotas = (mascota) => {
            const obj = {
                  Nombre: mascota.Nombre,
                  Edad: mascota.Edad,
                  Plan: mascota.Plan,
                  Raza: mascota.Raza,
                  Sexo: mascota.Sexo,
                  Tipo: mascota.Tipo,
                  IdPropietario: mascota.Cedula,
                  Valor: mascota.Valor,
            };

            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/mascotas",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              title: res,
                              icon: "success",
                              confirmButtonText: "Listo",
                              confirmButtonColor: "#4c4",
                        }).then((res) => {
                              if (res.isConfirmed) {
                                    window.location = "/mapa";
                              }
                        })
                  );
      };

      return (
            <>
                  <Header />
                  <Box
                        sx={{
                              bgcolor: "white",
                              height: "100vh",
                              alignContent: "center",
                              justifyContent: "center",
                        }}
                  >
                        <Grid
                              container
                              justifyContent="center"
                              xs={12}
                              sm={12}
                              md={12}
                              alignItems="center"
                              style={{ marginTop: "70px" }}
                        >
                              <Grid
                                    container
                                    justifyContent="center"
                                    xs={10}
                                    sm={10}
                                    md={9}
                              >
                                    <Formik
                                          initialValues={{
                                                Nombres: "",
                                                Cedula: "",
                                                Direccion: "",
                                                Telefono: "",
                                                Nombre: "",
                                                Edad: "",
                                                Plan: "",
                                                Sexo: "",
                                                Raza: "",
                                                Tipo: "",
                                                Valor: "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Nombres: Yup.string()
                                                      .matches(
                                                            /^[A-Za-z\sÀ-ÿ]+$/,
                                                            "* Solo se admiten letras"
                                                      )
                                                      .required("* Requerido"),

                                                Cedula: Yup.number(
                                                      "* Solo se admiten números"
                                                ).required("* Requerido"),

                                                Direccion:
                                                      Yup.string().required(
                                                            "* Requerido"
                                                      ),
                                                Telefono: Yup.number(
                                                      "* Solo se admiten números"
                                                ).required("* Requerido"),
                                                Nombre: Yup.string()
                                                      .matches(
                                                            /^[A-Za-z\sÀ-ÿ]+$/,
                                                            "* Solo se admiten letras"
                                                      )
                                                      .required("* Requerido"),
                                                Edad: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Sexo: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Plan: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Valor: Yup.number(
                                                      "* Solo se admiten números"
                                                ).required("* Requerido"),
                                                Tipo: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                                Raza: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                guardar(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                handleBlur,
                                                handleChange,
                                                setFieldValue,
                                                resetForm,
                                          }) => (
                                                <Form>
                                                      <Grid
                                                            container
                                                            spacing={2}
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            p={5}
                                                      >
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Typography
                                                                        variant="h5"
                                                                        component="h2"
                                                                        align="center"
                                                                        style={{
                                                                              marginTop:
                                                                                    "9px",
                                                                              color: "#1b1b1b",
                                                                        }}
                                                                  >
                                                                        <b>
                                                                              SERVICIO
                                                                              PARTICULAR
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h4"
                                                                  >
                                                                        <b>
                                                                              *
                                                                              INFORMACIÓN
                                                                              DEL
                                                                              TITULAR
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Nombres"
                                                                        label="Nombre y Apellidos *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Nombres
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Nombres &&
                                                                                    errors.Nombres
                                                                        )}
                                                                        helperText={
                                                                              touched.Nombres &&
                                                                              errors.Nombres
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Cedula"
                                                                        label="Cedula *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Cedula
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Cedula &&
                                                                                    errors.Cedula
                                                                        )}
                                                                        helperText={
                                                                              touched.Cedula &&
                                                                              errors.Cedula
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Direccion"
                                                                        label="Dirección de Residencia *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Direccion
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Direccion &&
                                                                                    errors.Direccion
                                                                        )}
                                                                        helperText={
                                                                              touched.Direccion &&
                                                                              errors.Direccion
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={6}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Telefono"
                                                                        label="Télefono *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Telefono
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Telefono &&
                                                                                    errors.Telefono
                                                                        )}
                                                                        helperText={
                                                                              touched.Telefono &&
                                                                              errors.Telefono
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h4"
                                                                  >
                                                                        <b>
                                                                              *
                                                                              MASCOTA
                                                                              FALLECIDA
                                                                        </b>
                                                                  </Typography>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        id="Nombre"
                                                                        name="Nombre"
                                                                        label="Nombre *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Nombre
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Nombre &&
                                                                                    errors.Nombre
                                                                        )}
                                                                        helperText={
                                                                              touched.Nombre &&
                                                                              errors.Nombre
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="center"
                                                            >
                                                                  <FormControl
                                                                        variant="filled"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Tipo
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="Tipo"
                                                                              value={
                                                                                    values.Tipo
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Tipo &&
                                                                                          errors.Tipo
                                                                              )}
                                                                              helperText={
                                                                                    touched.Tipo &&
                                                                                    errors.Tipo
                                                                              }
                                                                        >
                                                                              <MenuItem
                                                                                    value={
                                                                                          "PERRO"
                                                                                    }
                                                                              >
                                                                                    PERRO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "GATO"
                                                                                    }
                                                                              >
                                                                                    GATO
                                                                              </MenuItem>
                                                                        </Select>
                                                                  </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Raza"
                                                                        label="Raza *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Raza
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Raza &&
                                                                                    errors.Raza
                                                                        )}
                                                                        helperText={
                                                                              touched.Raza &&
                                                                              errors.Raza
                                                                        }
                                                                  />
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={2}
                                                                  justifyContent="center"
                                                            >
                                                                  <FormControl
                                                                        variant="filled"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Sexo
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="Sexo"
                                                                              value={
                                                                                    values.Sexo
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Sexo &&
                                                                                          errors.Sexo
                                                                              )}
                                                                              helperText={
                                                                                    touched.Sexo &&
                                                                                    errors.Sexo
                                                                              }
                                                                        >
                                                                              <MenuItem
                                                                                    value={
                                                                                          "MACHO"
                                                                                    }
                                                                              >
                                                                                    MACHO
                                                                              </MenuItem>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "HEMBRA"
                                                                                    }
                                                                              >
                                                                                    HEMBRA
                                                                              </MenuItem>
                                                                        </Select>
                                                                  </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={2}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Edad"
                                                                        label="Edad *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="text"
                                                                        size="small"
                                                                        value={
                                                                              values.Edad
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Edad &&
                                                                                    errors.Edad
                                                                        )}
                                                                        helperText={
                                                                              touched.Edad &&
                                                                              errors.Edad
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="start"
                                                            >
                                                                  <FormControl
                                                                        variant="filled"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel htmlFor="grouped-select">
                                                                              Planes
                                                                        </InputLabel>
                                                                        <Select
                                                                              id="grouped-select"
                                                                              name="Plan"
                                                                              value={
                                                                                    values.Plan
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Plan &&
                                                                                          errors.Plan
                                                                              )}
                                                                              helperText={
                                                                                    touched.Plan &&
                                                                                    errors.Plan
                                                                              }
                                                                        >
                                                                              <ListSubheader>
                                                                                    TIPO
                                                                                    PLAN
                                                                              </ListSubheader>
                                                                              <MenuItem
                                                                                    value={
                                                                                          "PLAN PARTICULAR MINY"
                                                                                    }
                                                                              >
                                                                                    PLAN
                                                                                    PARTICULAR
                                                                                    MINY
                                                                              </MenuItem>

                                                                              <MenuItem
                                                                                    value={
                                                                                          "PLAN PARTICULAR TOBBY"
                                                                                    }
                                                                              >
                                                                                    PLAN
                                                                                    PARTICULAR
                                                                                    TOBBY
                                                                              </MenuItem>

                                                                              <MenuItem
                                                                                    value={
                                                                                          "PLAN PARTICULAR TOGO"
                                                                                    }
                                                                              >
                                                                                    PLAN
                                                                                    PARTICULAR
                                                                                    TOGO
                                                                              </MenuItem>

                                                                              <MenuItem
                                                                                    value={
                                                                                          "PLAN PARTICULAR MAMUT"
                                                                                    }
                                                                              >
                                                                                    PLAN
                                                                                    PARTICULAR
                                                                                    MAMUT
                                                                              </MenuItem>
                                                                        </Select>
                                                                  </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                  item
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={4}
                                                                  justifyContent="center"
                                                            >
                                                                  <TextField
                                                                        name="Valor"
                                                                        label="Valor *"
                                                                        fullWidth
                                                                        margin="none"
                                                                        variant="filled"
                                                                        type="number"
                                                                        size="small"
                                                                        value={
                                                                              values.Valor
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Valor &&
                                                                                    errors.Valor
                                                                        )}
                                                                        helperText={
                                                                              touched.Valor &&
                                                                              errors.Valor
                                                                        }
                                                                  />
                                                            </Grid>

                                                            <Grid
                                                                  container
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  style={{
                                                                        marginTop:
                                                                              "15px",
                                                                        marginBottom:
                                                                              "15px",
                                                                  }}
                                                            >
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              height: "40px",
                                                                              background:
                                                                                    "#ff0000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                  >
                                                                        <Typography variant="h6">
                                                                              Registrar
                                                                        </Typography>
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Grid>
                        </Grid>
                  </Box>
                  <Footer />
            </>
      );
};
export default FormServicioParticular;
