import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
      DataGrid,
      gridPageCountSelector,
      gridPageSelector,
      useGridApiContext,
      useGridSelector /*esES*/,
} from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import { Container, Grid, Typography, Paper } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";
import { useSelector } from "react-redux";

const ListaFallecidos = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [muertos, setMuertos] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerFallecidos();
                  setActualizar(false);
            }
      }, [actualizar, selectedAgencia]);

      const traerFallecidos = () => {
            fetch(`https://dejandohuellas.syfacol.com/fallecidos/${selectedAgencia}`)
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        setMuertos(res);
                  });
      };

      const columns = [
            {
                  field: "Fecha",
                  headerName: "Murío",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <strong>{params.row.Fecha}</strong>
                        </div>
                  ),
            },

            {
                  field: "Nombre",
                  headerName: "Mascota",
                  width: 120,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Tipo",
                  headerName: "Tipo",
                  width: 100,
                  headerAlign: "center",
            },

            {
                  field: "Raza",
                  headerName: "Raza",
                  width: 100,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Edad",
                  headerName: "Edad",
                  width: 100,
                  headerAlign: "center",
            },
            {
                  field: "Posicion",
                  headerName: "Ubicación",
                  width: 100,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>{params.row.Posicion}</b>
                        </div>
                  ),
            },
            {
                  field: "Plan",
                  headerName: "Plan",
                  width: 200,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Valor",
                  headerName: "Pagó",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "15px",
                              }}
                        >
                              <b>{formatterPeso.format(params.row.Valor)}</b>
                        </div>
                  ),
            },
            {
                  field: "Nombres",
                  headerName: "Propietario",
                  width: 270,
                  headerAlign: "center",
                  editable: true,
            },
      ];

      const handleChange = (e) => {
            filtrarMuertos(e.target.value);
      };

      const filtrarMuertos = (termino) => {
            if (termino === "") {
                  setActualizar(true);
            } else {
                  fetch(
                        `https://dejandohuellas.syfacol.com/fallecidos/filtro/${termino}/${selectedAgencia}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setMuertos(res);
                        });
            }
      };

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      const Pagination = () => {
            const apiRef = useGridApiContext();
            const page = useGridSelector(apiRef, gridPageSelector);
            const pageCount = useGridSelector(apiRef, gridPageCountSelector);

            return (
                  <Pagination
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) =>
                              apiRef.current.setPage(value - 1)
                        }
                  />
            );
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>MASCOTAS FALLECIDAS</b>
                              </Typography>
                        </Grid>

                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    justifyContent="start"
                              >
                                    <TextField
                                          name="filtro"
                                          label="Buscar..."
                                          size="small"
                                          onChange={handleChange}
                                          variant="standard"
                                          margin="normal"
                                          InputProps={{
                                                endAdornment: (
                                                      <InputAdornment position="start">
                                                            <SearchIcon />
                                                      </InputAdornment>
                                                ),
                                          }}
                                    />
                              </Grid>

                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Contratos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={muertos}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default ListaFallecidos;
