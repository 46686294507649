import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { Container } from "reactstrap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, FormControl, InputLabel, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState, updateSelectedAgencia } from "../actions/actions";

const Header = () => {
      const [anchorEl, setAnchorEl] = React.useState(false);
      const [agencias, setAgencias] = useState([]);
      const open = Boolean(anchorEl);
      const navigate = useNavigate();
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );
      const dispatch = useDispatch();
      const idUser = sessionStorage.getItem("idUser");
      const rol = sessionStorage.getItem("rol");

      useEffect(() => {
            traerAgencias();
      }, []);

      const traerAgencias = () => {
            fetch("https://dejandohuellas.syfacol.com/agencias")
                  .then((res) => res.json())
                  .then((res) => setAgencias(res));
      };

      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
            console.log(setAnchorEl);
      };
      const handleClose = () => {
            setAnchorEl(null);
      };

      const sessionClose = () => {
            const fecha = new Date();

            const obj = {
                  hora_fin:
                        fecha.getFullYear() +
                        "-" +
                        (fecha.getMonth() + 1) +
                        "-" +
                        fecha.getDate() +
                        " " +
                        fecha.getHours() +
                        ":" +
                        fecha.getMinutes() +
                        ":" +
                        fecha.getSeconds(),
            };

            fetch(`http://localhost:9000/usuarios/logout/${idUser}`, {
                  method: "PUT",
                  headers: {
                        "Content-Type": "application/json",
                  },
                  body: JSON.stringify(obj),
            })
                  .then((res) => res.text())
                  .then((data) => {
                        // Aquí puedes manejar la respuesta del backend si es necesario
                        console.log(data);
                  })
                  .catch((error) => {
                        // Manejo de errores si la solicitud falla
                        console.error("Error al cerrar sesión:", error);
                  });

            sessionStorage.clear();
            dispatch(resetState());
            navigate("/");
      };

      const handleChange = (e) => {
            const selectedValue = e.target.value;
            dispatch(updateSelectedAgencia(selectedValue));
            console.log(selectedAgencia);
      };

      return (
            <header className="header">
                  <Container>
                        <div className="navigation">
                              <div className="logo">
                                    <a href="/es">
                                          <img
                                                src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/logoEmpresa.png"
                                                }
                                                width="210px"
                                          />
                                    </a>
                              </div>
                              {rol === "1" ? (
                                    <div>
                                          <FormControl
                                                sx={{ m: 1, minWidth: 200 }}
                                                size="small"
                                          >
                                                <InputLabel id="demo-select-small-label">
                                                      Agencia
                                                </InputLabel>
                                                <Select
                                                      labelId="demo-select-small-label"
                                                      id="demo-select-small"
                                                      value={selectedAgencia}
                                                      onChange={handleChange}
                                                >
                                                      {agencias.map((a) => (
                                                            <MenuItem
                                                                  key={a.Id}
                                                                  value={a.Id}
                                                            >
                                                                  {a.Ciudad}
                                                            </MenuItem>
                                                      ))}
                                                </Select>
                                          </FormControl>
                                    </div>
                              ) : null}

                              <div>
                                    <div
                                          className="custom_search"
                                          id="basic-button"
                                          aria-controls={
                                                open ? "basic-menu" : undefined
                                          }
                                          aria-haspopup="true"
                                          aria-expanded={
                                                open ? "true" : undefined
                                          }
                                          onClick={handleClick}
                                    >
                                          <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="24"
                                                height="24"
                                          >
                                                <path
                                                      fill="none"
                                                      d="M0 0h24v24H0z"
                                                />
                                                <path
                                                      d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
                                                      fill="rgba(255,255,255,1)"
                                                />
                                          </svg>
                                          <b>
                                                {sessionStorage.getItem("user")}
                                          </b>
                                    </div>

                                    <Menu
                                          id="basic-menu"
                                          anchorEl={anchorEl}
                                          open={open}
                                          onClose={handleClose}
                                          MenuListProps={{
                                                "aria-labelledby":
                                                      "basic-button",
                                          }}
                                    >
                                          <MenuItem
                                                onClick={handleClose}
                                                sx={{
                                                      display: {
                                                            sm: "none",
                                                            md: "none",
                                                            lg: "none",
                                                      },
                                                }}
                                          >
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="24"
                                                      height="24"
                                                      style={{
                                                            marginRight: "2px",
                                                      }}
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M20 22H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                                {sessionStorage.getItem("user")}
                                          </MenuItem>
                                          <MenuItem onClick={sessionClose}>
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="24"
                                                      height="24"
                                                      style={{
                                                            marginRight: "2px",
                                                      }}
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                                Cerrar Sesión
                                          </MenuItem>
                                    </Menu>
                              </div>
                        </div>
                  </Container>
            </header>
      );
};
export default Header;
