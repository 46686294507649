export const updateSelectedAgencia = (selectedAgencia) => {
      return {
            type: "UPDATE_SELECTED_AGENCIA",
            payload: selectedAgencia,
      };
};

export const resetState = () => {
      return {
            type: "RESET_STATE",
      };
};
