import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "./dashboard.css";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";

const Dashboard = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [ventanas, setVentanas] = useState([]);

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  switch (sessionStorage.getItem("rol")) {
                        case "1":
                              setVentanas([
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0L24 0 24 24 0 24z"
                                                      />
                                                      <path
                                                            d="M20 2c.552 0 1 .448 1 1v3.757l-2 2V4H5v16h14v-2.758l2-2V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h16zm1.778 6.808l1.414 1.414L15.414 18l-1.416-.002.002-1.412 7.778-7.778zM13 12v2H8v-2h5zm3-4v2H8V8h8z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "AFILIACIONES",
                                          text: "Agregar nuevos afiliados",
                                          url: "/afiliaciones",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "CLIENTES",
                                          text: "Consulta de todos los clientes y de cobros",
                                          url: "/gestion",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "LABOR DIARIA",
                                          text: "Labor de Cobros Hoy",
                                          url: "/labor",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "COBROS PARA HOY",
                                          text: "Gestión de Cobros",
                                          url: "/cobros",
                                    },

                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0H24V24H0z"
                                                      />
                                                      <path
                                                            d="M5 3v16h16v2H3V3h2zm14.94 2.94l2.12 2.12L16 14.122l-3-3-3.94 3.94-2.12-2.122L13 6.88l3 3 3.94-3.94z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "GRAFICAS",
                                          text: "Informe de Gráficas Estadisticas",
                                          url: "/graficas",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M15.936 2.5L21.5 8.067v7.87L15.936 21.5h-7.87L2.5 15.936v-7.87L8.066 2.5h7.87zm-.829 2H8.894L4.501 8.895v6.213l4.393 4.394h6.213l4.394-4.394V8.894l-4.394-4.393zM8 11h8v2H8v-2z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "RETIRADOS",
                                          text: "Clientes Desafiliados",
                                          url: "/retirados",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM11 10V7h2v3h3v2h-3v3h-2v-3H8v-2h3z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "FALLECIDOS",
                                          text: "Mascotas Fallecidas",
                                          url: "/fallecidos",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M11.39 10.39L7.5 14.277 3.61 10.39a5.5 5.5 0 1 1 7.78 0zM7.5 8.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm12.89 10.89l-3.89 3.888-3.89-3.889a5.5 5.5 0 1 1 7.78 0zM16.5 17.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "CEMENTERIO",
                                          text: "Ubicación de mascotas en el cementerio",
                                          url: "/mapa",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      ></path>
                                                      <path
                                                            d="M12 11C14.7614 11 17 13.2386 17 16V22H15V16C15 14.4023 13.7511 13.0963 12.1763 13.0051L12 13C10.4023 13 9.09634 14.2489 9.00509 15.8237L9 16V22H7V16C7 13.2386 9.23858 11 12 11ZM5.5 14C5.77885 14 6.05009 14.0326 6.3101 14.0942C6.14202 14.594 6.03873 15.122 6.00896 15.6693L6 16L6.0007 16.0856C5.88757 16.0456 5.76821 16.0187 5.64446 16.0069L5.5 16C4.7203 16 4.07955 16.5949 4.00687 17.3555L4 17.5V22H2V17.5C2 15.567 3.567 14 5.5 14ZM18.5 14C20.433 14 22 15.567 22 17.5V22H20V17.5C20 16.7203 19.4051 16.0796 18.6445 16.0069L18.5 16C18.3248 16 18.1566 16.03 18.0003 16.0852L18 16C18 15.3343 17.8916 14.694 17.6915 14.0956C17.9499 14.0326 18.2211 14 18.5 14ZM5.5 8C6.88071 8 8 9.11929 8 10.5C8 11.8807 6.88071 13 5.5 13C4.11929 13 3 11.8807 3 10.5C3 9.11929 4.11929 8 5.5 8ZM18.5 8C19.8807 8 21 9.11929 21 10.5C21 11.8807 19.8807 13 18.5 13C17.1193 13 16 11.8807 16 10.5C16 9.11929 17.1193 8 18.5 8ZM5.5 10C5.22386 10 5 10.2239 5 10.5C5 10.7761 5.22386 11 5.5 11C5.77614 11 6 10.7761 6 10.5C6 10.2239 5.77614 10 5.5 10ZM18.5 10C18.2239 10 18 10.2239 18 10.5C18 10.7761 18.2239 11 18.5 11C18.7761 11 19 10.7761 19 10.5C19 10.2239 18.7761 10 18.5 10ZM12 2C14.2091 2 16 3.79086 16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2ZM12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4Z"
                                                            fill="rgba(255,0,0,1)"
                                                      ></path>
                                                </svg>
                                          ),
                                          title: "EMPLEADOS",
                                          text: "Trabajadores de la funeraria",
                                          url: "/empleados",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      ></path>
                                                      <path
                                                            d="M21 21H3C2.44772 21 2 20.5523 2 20V12.4868C2 12.1978 2.12501 11.9229 2.34282 11.733L6 8.54435V4C6 3.44772 6.44772 3 7 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21ZM9 19H12V12.9416L8 9.45402L4 12.9416V19H7V15H9V19ZM14 19H20V5H8V7.12729C8.23444 7.12729 8.46888 7.20938 8.65718 7.37355L13.6572 11.733C13.875 11.9229 14 12.1978 14 12.4868V19ZM16 11H18V13H16V11ZM16 15H18V17H16V15ZM16 7H18V9H16V7ZM12 7H14V9H12V7Z"
                                                            fill="rgba(255,0,0,1)"
                                                      ></path>
                                                </svg>
                                          ),
                                          title: "AGENCIAS",
                                          text: "Agencias a Nivel Nacional",
                                          url: "/agencias",
                                    },
                              ]);
                              break;
                        case "2":
                              setVentanas([
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0L24 0 24 24 0 24z"
                                                      />
                                                      <path
                                                            d="M20 2c.552 0 1 .448 1 1v3.757l-2 2V4H5v16h14v-2.758l2-2V21c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h16zm1.778 6.808l1.414 1.414L15.414 18l-1.416-.002.002-1.412 7.778-7.778zM13 12v2H8v-2h5zm3-4v2H8V8h8z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "AFILIACIONES",
                                          text: "Agregar nuevos afiliados",
                                          url: "/afiliaciones",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "CLIENTES",
                                          text: "Consulta de todos los clientes y de cobros",
                                          url: "/gestion",
                                    },
                              ]);
                              break;

                        case "3":
                              setVentanas([
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "CLIENTES",
                                          text: "Consulta de todos los clientes y de cobros",
                                          url: "/gestion",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M9 1v2h6V1h2v2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2zm11 9H4v9h16v-9zm-4.964 1.136l1.414 1.414-4.95 4.95-3.536-3.536L9.38 12.55l2.121 2.122 3.536-3.536zM7 5H4v3h16V5h-3v1h-2V5H9v1H7V5z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "LABOR DIARIA",
                                          text: "Labor de Cobros Hoy",
                                          url: "/labor",
                                    },
                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-3.5-6H14a.5.5 0 1 0 0-1h-4a2.5 2.5 0 1 1 0-5h1V6h2v2h2.5v2H10a.5.5 0 1 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "COBROS PARA HOY",
                                          text: "Gestión de Cobros",
                                          url: "/cobros",
                                    },

                                    {
                                          icon: (
                                                <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="50"
                                                      height="50"
                                                >
                                                      <path
                                                            fill="none"
                                                            d="M0 0h24v24H0z"
                                                      />
                                                      <path
                                                            d="M15.936 2.5L21.5 8.067v7.87L15.936 21.5h-7.87L2.5 15.936v-7.87L8.066 2.5h7.87zm-.829 2H8.894L4.501 8.895v6.213l4.393 4.394h6.213l4.394-4.394V8.894l-4.394-4.393zM8 11h8v2H8v-2z"
                                                            fill="rgba(255,0,0,1)"
                                                      />
                                                </svg>
                                          ),
                                          title: "RETIRADOS",
                                          text: "Clientes Desafiliados",
                                          url: "/retirados",
                                    },
                              ]);
                              break;
                        default:
                              //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
                              break;
                  }
            }
      }, []);

      return (
            <>
                  <Header />
                  <section className=" pt-5">
                        <Container>
                              <Row className="p-5">
                                    {ventanas.map((item) => (
                                          <Col
                                                lg="4"
                                                md="4"
                                                sm="12"
                                                xs="12"
                                                className="mb-4 "
                                                key={item.id}
                                          >
                                                <div className="single_product">
                                                      {item.icon}
                                                      <h6>{item.title}</h6>
                                                      <p>{item.text}</p>
                                                      <div className="d-flex align-items-center justify-content-center">
                                                            <button
                                                                  className="btn"
                                                                  onClick={() =>
                                                                        navigate(
                                                                              `${item.url}`
                                                                        )
                                                                  }
                                                            >
                                                                  ENTRAR
                                                            </button>
                                                      </div>
                                                </div>
                                          </Col>
                                    ))}
                              </Row>
                        </Container>
                  </section>
                  <Footer />
            </>
      );
};

export default Dashboard;
