import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { DataGrid /*esES*/ } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales/esES";
import Swal from "sweetalert2";
import { Container, Grid, Typography, Button, Paper, Box } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import Stack from "@mui/material/Stack";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../footer/Footer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useSelector } from "react-redux";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";

const StyledBadge = withStyles((theme) => ({
      badge: {
            right: -3,
            top: 13,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: "0 4px",
      },
}))(Badge);

const fecha = new Date();

const Empleados = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [empleados, setEmpleados] = useState([]);
      const [agencias, setAgencias] = useState([]);
      const [roles, setRoles] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const [openModal, setOpenModal] = useState(false);
      const [data, setData] = useState([]);
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );
      const theme = createTheme();

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  traerEmpleados();
                  traerAgencias();
                  traerRoles();
                  setActualizar(false);
            }
      }, [actualizar, selectedAgencia]);

      const traerEmpleados = () => {
            fetch(`https://dejandohuellas.syfacol.com/usuarios/todos/${selectedAgencia}`)
                  .then((res) => res.json())
                  .then((res) => {
                        setSize(res.length);
                        setEmpleados(res);
                  });
      };

      const traerAgencias = () => {
            fetch("https://dejandohuellas.syfacol.com/agencias/")
                  .then((res) => res.json())
                  .then((res) => setAgencias(res));
      };

      const traerRoles = () => {
            fetch("https://dejandohuellas.syfacol.com/usuarios/roles")
                  .then((res) => res.json())
                  .then((res) => setRoles(res));
      };

      const columns = [
            {
                  field: "Id",
                  headerName: "No.",
                  width: 70,
                  headerAlign: "center",
                  align: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.Id}</b>
                        </Stack>
                  ),
            },

            {
                  field: "User",
                  headerName: "Nombre",
                  width: 210,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Correo",
                  headerName: "Correo",
                  width: 260,
                  headerAlign: "center",
                  editable: true,
                  renderCell: (params) => (
                        <a href={`mailto:${params.row.Correo}`}>
                              {params.row.Correo}
                        </a>
                  ),
            },
            {
                  field: "Password",
                  headerName: "Contraseña",
                  width: 210,
                  headerAlign: "center",
                  editable: true,
            },
            {
                  field: "Nombre_Rol",
                  headerName: "Rol",
                  width: 160,
                  headerAlign: "center",

                  renderCell: (params) => (
                        <div
                              aria-haspopup="true"
                              style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                    fontSize: "14px",
                                    color: "white",

                                    background: `${
                                          (params.row.Nombre_Rol ===
                                                "ADMINISTRADOR" &&
                                                " #2ed600") ||
                                          (params.row.Nombre_Rol === "ASESOR" &&
                                                "blue") ||
                                          (params.row.Nombre_Rol ===
                                                "COBRADOR" &&
                                                "yellow")
                                    }`,
                              }}
                        >
                              <b>{params.row.Nombre_Rol}</b>
                        </div>
                  ),
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 200,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Modificar"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                openModalEditar(params.row)
                                          }
                                    >
                                          <CreateIcon />
                                    </IconButton>
                              </Tooltip>
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Eliminar"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                confirmDelete(params.row)
                                          }
                                    >
                                          <DeleteForeverIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const handleChange = (e) => {
            filtrarEmpleados(e.target.value);
      };

      const filtrarEmpleados = (termino) => {
            if (termino === "") {
                  setActualizar(true);
            } else {
                  fetch(
                        `https://dejandohuellas.syfacol.com/usuarios/filtro/${termino}/${selectedAgencia}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setEmpleados(res);
                        });
            }
      };

      const openModalEditar = (user) => {
            setData(user);
            setOpenModal(!openModal);
      };

      const closeModalEditar = () => {
            setOpenModal(!openModal);
            setData([]);
      };

      const saveUser = (values) => {
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch("https://dejandohuellas.syfacol.com/usuarios/registro", requestInit)
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 4000,
                        })
                  );

            setActualizar(true);
            setOpenModal(!openModal);
      };

      const updateUser = (values) => {
            const obj = {
                  User: values.User,
                  Correo: values.Correo,
                  Password: values.Password,
                  Rol: values.Rol,
                  Agencia: values.Agencia,
            };
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(obj),
            };

            fetch(
                  `https://dejandohuellas.syfacol.com/usuarios/editar/${data.Id}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => {
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 4000,
                        });

                        setActualizar(!actualizar);
                        closeModalEditar();
                  });
      };

      const confirmDelete = (user) => {
            Swal.fire({
                  title: "¿Estas seguro?",
                  text: "Estas a punto de borrar a: " + user.User,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "green",
                  cancelButtonColor: "red",
                  confirmButtonText: "¡Sí, bórralo!",
                  cancelButtonText: "Cancelar",
            }).then((result) => {
                  if (result.isConfirmed) {
                        deleteUser(user.Id);
                  }
            });
      };

      const deleteUser = (id) => {
            const requestInit = {
                  method: "DELETE",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(`https://dejandohuellas.syfacol.com/usuarios/eliminar/${id}`, requestInit)
                  .then((res) => res.text())
                  .then((res) => {
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: res,
                              showConfirmButton: false,
                              timer: 4000,
                        });
                  });
            setActualizar(!actualizar);
      };

      const isNewRecord = Object.keys(data).length === 0;

      const initialValues = {
            User: "",
            Correo: "",
            Password: "",
            Rol: "",
            Agencia: "",
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Dialog open={openModal} onClose={closeModalEditar}>
                              <div>
                                    <Formik
                                          initialValues={
                                                isNewRecord
                                                      ? initialValues
                                                      : data
                                          }
                                          validationSchema={Yup.object().shape({
                                                User: Yup.string()
                                                      .matches(
                                                            /^[A-Za-z\sÀ-ÿ]+$/,
                                                            "* Solo se admiten letras"
                                                      )
                                                      .required("* Requerido"),
                                                Correo: Yup.string()
                                                      .email(
                                                            "* Ingrese un correo valido"
                                                      )
                                                      .required(
                                                            "* Correo Requerido"
                                                      ),
                                                Password: Yup.string()
                                                      .required(
                                                            "* Ingrese su contraseña"
                                                      )
                                                      .min(
                                                            8,
                                                            "* La contraseña debe tener al menos 8 caracteres"
                                                      )
                                                      .matches(
                                                            /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).*$/,
                                                            "* La contraseña debe contener al menos un número, una letra y un carácter especial"
                                                      ),
                                                Rol: Yup.string().required(
                                                      "* Rol Requerido"
                                                ),
                                                Agencia: Yup.string().required(
                                                      "* Agencia Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                if (isNewRecord) {
                                                      saveUser(values);
                                                } else {
                                                      updateUser(values);
                                                }
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form
                                                      style={{
                                                            padding: "40px",
                                                      }}
                                                >
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            md={12}
                                                      >
                                                            <div
                                                                  style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        justifyContent:
                                                                              "space-between",
                                                                        alignItems:
                                                                              "center",
                                                                  }}
                                                            >
                                                                  <Typography
                                                                        variant="h5"
                                                                        component="h2"
                                                                        align="start"
                                                                        sx={{
                                                                              color: "#800000",
                                                                        }}
                                                                  >
                                                                        {isNewRecord ? (
                                                                              <b>
                                                                                    Registrar
                                                                                    Usuario
                                                                              </b>
                                                                        ) : (
                                                                              <b>
                                                                                    Modificar
                                                                                    Usuario
                                                                              </b>
                                                                        )}
                                                                  </Typography>
                                                                  <Tooltip
                                                                        interactive
                                                                        placement={
                                                                              "top"
                                                                        }
                                                                        title={
                                                                              "Cerrar"
                                                                        }
                                                                  >
                                                                        <IconButton
                                                                              aria-label="details"
                                                                              style={{
                                                                                    color: "#800000",
                                                                              }}
                                                                              size={
                                                                                    "medium"
                                                                              }
                                                                              onClick={
                                                                                    closeModalEditar
                                                                              }
                                                                        >
                                                                              <CancelRoundedIcon />
                                                                        </IconButton>
                                                                  </Tooltip>
                                                            </div>

                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 2,
                                                                  }}
                                                            >
                                                                  <PermIdentityOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        name="User"
                                                                        label="Nombre *"
                                                                        error={Boolean(
                                                                              touched.User &&
                                                                                    errors.User
                                                                        )}
                                                                        helperText={
                                                                              touched.User &&
                                                                              errors.User
                                                                        }
                                                                        value={
                                                                              values.User
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        type="text"
                                                                        fullWidth
                                                                        sx={{
                                                                              "& label.Mui-focused":
                                                                                    {
                                                                                          color: "#800000",
                                                                                    },
                                                                              "& .MuiOutlinedInput-root":
                                                                                    {
                                                                                          "& fieldset":
                                                                                                {
                                                                                                      border: "solid 2px #463e3e",
                                                                                                },
                                                                                          "&:hover fieldset":
                                                                                                {
                                                                                                      border: "red solid 2px",
                                                                                                },
                                                                                          "&.Mui-focused fieldset":
                                                                                                {
                                                                                                      borderColor:
                                                                                                            "red",
                                                                                                },
                                                                                    },
                                                                        }}
                                                                  />
                                                            </Box>
                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 1,
                                                                  }}
                                                            >
                                                                  <AlternateEmailOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        type="email"
                                                                        name="Correo"
                                                                        label="Correo *"
                                                                        error={Boolean(
                                                                              touched.Correo &&
                                                                                    errors.Correo
                                                                        )}
                                                                        helperText={
                                                                              touched.Correo &&
                                                                              errors.Correo
                                                                        }
                                                                        value={
                                                                              values.Correo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        fullWidth
                                                                  />
                                                            </Box>

                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 1,
                                                                  }}
                                                            >
                                                                  <LockOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        type="text"
                                                                        name="Password"
                                                                        label="Contraseña *"
                                                                        error={Boolean(
                                                                              touched.Password &&
                                                                                    errors.Password
                                                                        )}
                                                                        helperText={
                                                                              touched.Password &&
                                                                              errors.Password
                                                                        }
                                                                        value={
                                                                              values.Password
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        fullWidth
                                                                  />
                                                            </Box>
                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 2,
                                                                  }}
                                                            >
                                                                  <MapsHomeWorkOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <FormControl
                                                                        variant="standard"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Agencia
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="Agencia"
                                                                              value={
                                                                                    values.Agencia
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Agencia &&
                                                                                          errors.Agencia
                                                                              )}
                                                                              helperText={
                                                                                    touched.Agencia &&
                                                                                    errors.Agencia
                                                                              }
                                                                              fullWidth
                                                                        >
                                                                              {agencias.map(
                                                                                    (
                                                                                          a
                                                                                    ) => (
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      a.Id
                                                                                                }
                                                                                          >
                                                                                                {
                                                                                                      a.Ciudad
                                                                                                }
                                                                                          </MenuItem>
                                                                                    )
                                                                              )}
                                                                        </Select>
                                                                  </FormControl>
                                                            </Box>

                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 2,
                                                                  }}
                                                            >
                                                                  <CoPresentOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <FormControl
                                                                        variant="standard"
                                                                        fullWidth
                                                                        size="small"
                                                                  >
                                                                        <InputLabel id="demo-simple-select-filled-label">
                                                                              Tipo
                                                                              de
                                                                              Usuario
                                                                              *
                                                                        </InputLabel>
                                                                        <Select
                                                                              labelId="demo-simple-select-filled-label"
                                                                              id="demo-simple-select-filled"
                                                                              name="Rol"
                                                                              value={
                                                                                    values.Rol
                                                                              }
                                                                              onBlur={
                                                                                    handleBlur
                                                                              }
                                                                              onChange={
                                                                                    handleChange
                                                                              }
                                                                              error={Boolean(
                                                                                    touched.Rol &&
                                                                                          errors.Rol
                                                                              )}
                                                                              helperText={
                                                                                    touched.Rol &&
                                                                                    errors.Rol
                                                                              }
                                                                              fullWidth
                                                                        >
                                                                              {roles.map(
                                                                                    (
                                                                                          r
                                                                                    ) => (
                                                                                          <MenuItem
                                                                                                value={
                                                                                                      r.Id
                                                                                                }
                                                                                          >
                                                                                                {
                                                                                                      r.Nombre
                                                                                                }
                                                                                          </MenuItem>
                                                                                    )
                                                                              )}
                                                                        </Select>
                                                                  </FormControl>
                                                            </Box>

                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  sx={{ mt: 2 }}
                                                            >
                                                                  <Button
                                                                        fullWidth
                                                                        style={{
                                                                              background:
                                                                                    "#800000",
                                                                              color: "white",
                                                                              borderRadius:
                                                                                    "20px",
                                                                              maxWidth: "200px", // Ajusta el ancho máximo según tus necesidades
                                                                              margin: "0 auto",
                                                                        }}
                                                                        size="large"
                                                                        type="submit"
                                                                  >
                                                                        {isNewRecord
                                                                              ? "Registrar"
                                                                              : "Modificar"}
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>
                        </Dialog>

                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>Empleados</b>
                              </Typography>
                        </Grid>

                        <Grid container md={12}>
                              <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    justifyContent="flex-start"
                                    alignItems="center"
                              >
                                    <TextField
                                          name="filtro"
                                          label="Buscar..."
                                          size="small"
                                          onChange={handleChange}
                                          variant="standard"
                                          margin="normal"
                                          InputProps={{
                                                endAdornment: (
                                                      <InputAdornment position="start">
                                                            <SearchIcon />
                                                      </InputAdornment>
                                                ),
                                          }}
                                    />
                              </Grid>

                              <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    style={{
                                          justifyContent: "flex-end",
                                    }}
                              >
                                    <Button
                                          startIcon={<PersonAddIcon />}
                                          style={{
                                                background: "#ff0000",
                                                color: "white",
                                          }}
                                          variant="contained"
                                          onClick={() =>
                                                setOpenModal(!openModal)
                                          }
                                    >
                                          Nuevo
                                    </Button>
                              </Grid>
                        </Grid>

                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) => row.Id}
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                autoHeight
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Empleados por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={empleados}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default Empleados;
