import React from "react";
import { Col, Container, Row } from "reactstrap";
import "./footer.css";

const Footer = () => {
      const fecha = new Date();
      return (
            <footer className="footer">
                  <div className="footer_bottom">
                        <Container>
                              <Row>
                                    <Col lg="12">
                                          <p>
                                                Copyright ©{" "}
                                                {fecha.getFullYear()} Funeraria
                                                para mascotas Dejando Huellas.
                                                All Rights Reserved.
                                          </p>
                                          <p>Web Desing by RolandSpain</p>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </footer>
      );
};

export default Footer;
