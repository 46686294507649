// agenciaReducer.js

const initialState = {
      selectedAgencia: "",
};

const agenciaReducer = (state = initialState, action) => {
      switch (action.type) {
            case "UPDATE_SELECTED_AGENCIA":
                  return {
                        ...state,
                        selectedAgencia: action.payload,
                  };

            case "RESET_STATE":
                  return initialState; // Devuelve el estado inicial para resetearlo

            default:
                  return state;
      }
};

export default agenciaReducer;
