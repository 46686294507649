import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { Paper } from "@mui/material";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

const FormUsuario = () => {
      const saveUser = async (values) => {
            const requestInit = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/usuarios/registro",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) =>
                        Swal.fire({
                              position: "center",
                              icon: "success",
                              title: "Muy Bien",
                              text: res,
                              showConfirmButton: false,
                              timerProgressBar: true,
                              timer: 3000,
                        }).then(() => {
                              window.location = "/";
                        })
                  )
                  .catch((res) => {
                        const Toast = Swal.mixin({
                              toast: true,
                              position: "top-end",
                              showConfirmButton: false,
                              timer: 2000,
                              timerProgressBar: true,
                        });

                        Toast.fire({
                              icon: "error",
                              title: "Ups! Ha ocurrido un error",
                              text: res,
                        });
                  });
      };

      return (
            <Box
                  sx={{
                        bgcolor: "#fff",
                        height: "100vh",
                        alignContent: "center",
                        justifyContent: "center",
                  }}
            >
                  <Grid
                        container
                        justifyContent="center"
                        xs={12}
                        sm={12}
                        md={12}
                        alignItems="center"
                        sx={{ height: "100vh" }}
                  >
                        <Grid item xs={10} sm={8} md={4}>
                              <Paper
                                    style={{
                                          background: "#fff",
                                          borderRadius: "15px",
                                    }}
                                    elevation={24}
                              >
                                    <Formik
                                          initialValues={{
                                                User: "",
                                                Correo: "",
                                                Password: "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                                User: Yup.string()
                                                      .matches(
                                                            /^[A-Za-z\sÀ-ÿ]+$/,
                                                            "* Solo se admiten letras"
                                                      )
                                                      .required("* Requerido"),
                                                Correo: Yup.string()
                                                      .email(
                                                            "* Ingrese un correo valido"
                                                      )
                                                      .required(
                                                            "* Correo Requerido"
                                                      ),
                                                Password: Yup.string().required(
                                                      "* Ingrese su contraseña"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                saveUser(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form
                                                      style={{
                                                            padding: "40px",
                                                      }}
                                                >
                                                      <Grid
                                                            container
                                                            xs={12}
                                                            md={12}
                                                      >
                                                            <Typography
                                                                  variant="h5"
                                                                  component="h2"
                                                                  align="start"
                                                                  sx={{
                                                                        color: "#800000",
                                                                  }}
                                                            >
                                                                  <b>
                                                                        Registro
                                                                        Usuario
                                                                  </b>
                                                            </Typography>
                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 2,
                                                                  }}
                                                            >
                                                                  <PermIdentityOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        name="User"
                                                                        label="Nombre *"
                                                                        error={Boolean(
                                                                              touched.User &&
                                                                                    errors.User
                                                                        )}
                                                                        helperText={
                                                                              touched.User &&
                                                                              errors.User
                                                                        }
                                                                        value={
                                                                              values.User
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        type="text"
                                                                        fullWidth
                                                                        sx={{
                                                                              "& label.Mui-focused":
                                                                                    {
                                                                                          color: "#800000",
                                                                                    },
                                                                              "& .MuiOutlinedInput-root":
                                                                                    {
                                                                                          "& fieldset":
                                                                                                {
                                                                                                      border: "solid 2px #463e3e",
                                                                                                },
                                                                                          "&:hover fieldset":
                                                                                                {
                                                                                                      border: "red solid 2px",
                                                                                                },
                                                                                          "&.Mui-focused fieldset":
                                                                                                {
                                                                                                      borderColor:
                                                                                                            "red",
                                                                                                },
                                                                                    },
                                                                        }}
                                                                  />
                                                            </Box>
                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 1,
                                                                  }}
                                                            >
                                                                  <AlternateEmailOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        type="email"
                                                                        name="Correo"
                                                                        label="Correo *"
                                                                        error={Boolean(
                                                                              touched.Correo &&
                                                                                    errors.Correo
                                                                        )}
                                                                        helperText={
                                                                              touched.Correo &&
                                                                              errors.Correo
                                                                        }
                                                                        value={
                                                                              values.Correo
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        fullWidth
                                                                  />
                                                            </Box>

                                                            <Box
                                                                  sx={{
                                                                        display: "flex",
                                                                        alignItems:
                                                                              "flex-end",
                                                                        width: "100%",
                                                                        mt: 1,
                                                                  }}
                                                            >
                                                                  <LockOutlinedIcon
                                                                        sx={{
                                                                              color: "#800000",
                                                                              mr: 1,
                                                                              my: 0.5,
                                                                        }}
                                                                  />
                                                                  <TextField
                                                                        variant="standard"
                                                                        type="text"
                                                                        name="Password"
                                                                        label="Contraseña *"
                                                                        error={Boolean(
                                                                              touched.Password &&
                                                                                    errors.Password
                                                                        )}
                                                                        helperText={
                                                                              touched.Password &&
                                                                              errors.Password
                                                                        }
                                                                        value={
                                                                              values.Password
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        fullWidth
                                                                  />
                                                            </Box>

                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                                  sx={{ mt: 2 }}
                                                            >
                                                                  <Button
                                                                        fullWidth
                                                                        style={{
                                                                              background:
                                                                                    "#800000",
                                                                              color: "white",
                                                                              borderRadius:
                                                                                    "20px",
                                                                        }}
                                                                        size="large"
                                                                        type="submit"
                                                                  >
                                                                        Registrar
                                                                  </Button>
                                                            </Grid>
                                                      </Grid>
                                                </Form>
                                          )}
                                    </Formik>
                              </Paper>
                        </Grid>
                  </Grid>
            </Box>
      );
};
export default FormUsuario;
