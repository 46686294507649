import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Este import asegura que se use el almacenamiento local del navegador
import agenciaReducer from "./components/reducers/agenciaReducer";

const rootReducer = combineReducers({
      agencia: agenciaReducer,
});

const persistConfig = {
      key: "root",
      storage,
      // Puedes incluir aquí otras configuraciones, como qué partes del estado persistir, etc.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
      persistedReducer,
      composeEnhancers(applyMiddleware())
);

export const persistor = persistStore(store); // Agregamos la persistencia del store

export default store;
