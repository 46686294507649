import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "chart.js/auto";
import { useNavigate } from "react-router-dom";
import Footer from "./footer/Footer";
import Header from "./Header/Header";

const Estimaciones = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [ingresosEstimados, setIngresosEstimados] = useState("");
      const [noCobros, setNoCobros] = useState("");
      const [moraDosMes, setMoraDosMes] = useState("");
      const [moraTresMes, setMoraTresMes] = useState("");
      const [noCobrosDos, setNoCobrosDos] = useState("");
      const [noCobrosTres, setNoCobrosTres] = useState("");
      const [afiliados, setAfiliados] = useState("");
      const [desafiliados, setDesafiliados] = useState("");
      const [mascota1, setMascotas1] = useState([]);
      const [mascota2, setMascotas2] = useState([]);
      const [mascota3, setMascotas3] = useState([]);
      const [mascota4, setMascotas4] = useState([]);
      const [mascotaMiny, setMascotasMiny] = useState("");
      const [mascotaTogo, setMascotasTogo] = useState("");
      const [mascotaMamut, setMascotasMamut] = useState("");
      const [mascotasTobby, setMascotasTobby] = useState("");
      const [totalMascotas, setTotalMascotas] = useState("");
      const [mascotasCategoria, setMascotasCategoria] = useState([]);
      const [dogsMachos, setDogMachos] = useState([]);
      const [dogsHembras, setDogHembras] = useState([]);
      const [catMachos, setCatMachos] = useState([]);
      const [catHembras, setCatHembras] = useState([]);

      const theme = createTheme();

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  reporteDosMesesMora();
                  reporteTresMesesMora();
                  clientesActivos();
                  clientesDesafiliados();
                  mascotasPorCliente();
                  totalMascotasMiny();
                  totalMascotasTogo();
                  totalMascotasMamut();
                  totalMascotasTobby();
                  totalMascotasActivas();
                  traerMascotasCategoria();
                  traerPerrosXSexo();
                  traerGatosXSexo();
            }
      }, []);

      const formatterPeso = new Intl.NumberFormat("es-CO", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
      });

      theme.typography.subtitle2 = {
            fontSize: "0.9rem",
            "@media (max-width:600px)": {
                  fontSize: "0.7rem",
            },
      };
      theme.typography.subtitle1 = {
            fontSize: "1rem",
            "@media (max-width:600px)": {
                  fontSize: "0.8rem",
            },
      };

      const traerTotalIngresosEstimados = (values) => {
            let fecha1 =
                  values.Fecha1.getFullYear() +
                  "-" +
                  (values.Fecha1.getMonth() + 1) +
                  "-" +
                  values.Fecha1.getDate();
            let fecha2 =
                  values.Fecha2.getFullYear() +
                  "-" +
                  (values.Fecha2.getMonth() + 1) +
                  "-" +
                  values.Fecha2.getDate();

            fetch(
                  `https://dejandohuellas.syfacol.com/pagos/ingresosEstimados/${fecha1}/${fecha2}`
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map(
                              (r) => (
                                    setNoCobros(r.COBROS_ESTIMADOS),
                                    setIngresosEstimados(r.INGRESOS_ESTIMADOS)
                              )
                        );
                  });
      };

      const traerDatos = (values) => {
            traerTotalIngresosEstimados(values);
      };

      const reporteDosMesesMora = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/dosmesesmora"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map(
                              (r) => (
                                    setMoraDosMes(r.TOTAL_MORA),
                                    setNoCobrosDos(r.NoCobros)
                              )
                        );
                  });
      };

      const reporteTresMesesMora = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/tresmesesmora"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map(
                              (r) => (
                                    setMoraTresMes(r.TOTAL_MORA),
                                    setNoCobrosTres(r.NoCobros)
                              )
                        );
                  });
      };

      const clientesActivos = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/clientesactivos"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setAfiliados(r.TOTAL));
                  });
      };

      const clientesDesafiliados = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/clientesdesafiliados"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setDesafiliados(r.TOTAL));
                  });
      };

      const mascotasPorCliente = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotasXcliente"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setMascotas1(res.filter((r) => r.Cantidad == 1));
                        setMascotas2(res.filter((r) => r.Cantidad == 2));
                        setMascotas3(res.filter((r) => r.Cantidad == 3));
                        setMascotas4(res.filter((r) => r.Cantidad >= 4));
                  });
      };

      const totalMascotasActivas = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/totalmascotas"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setTotalMascotas(r.TOTAL));
                  });
      };

      const totalMascotasMiny = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotasminy"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setMascotasMiny(r.TOTAL));
                  });
      };

      const totalMascotasTogo = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotastogo"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setMascotasTogo(r.TOTAL));
                  });
      };

      const totalMascotasMamut = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotasmamut"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setMascotasMamut(r.TOTAL));
                  });
      };

      const totalMascotasTobby = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotastobby"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        res.map((r) => setMascotasTobby(r.TOTAL));
                  });
      };

      const traerMascotasCategoria = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/mascotasxcategoria"
            )
                  .then((res) => res.json())
                  .then((res) => setMascotasCategoria(res));
      };

      const traerPerrosXSexo = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/sexoperros"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setDogMachos(res.filter((r) => r.Sexo === "MACHO"));
                        setDogHembras(res.filter((r) => r.Sexo === "HEMBRA"));
                  });
      };

      const traerGatosXSexo = () => {
            fetch(
                  "https://dejandohuellas.syfacol.com/reportes/sexogatos"
            )
                  .then((res) => res.json())
                  .then((res) => {
                        setCatMachos(res.filter((r) => r.Sexo === "MACHO"));
                        setCatHembras(res.filter((r) => r.Sexo === "HEMBRA"));
                  });
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Grid
                              container
                              md={12}
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                          variant="h5"
                                          component="h2"
                                          align="center"
                                          style={{
                                                marginTop: "12px",
                                                marginBottom: "12px",
                                          }}
                                    >
                                          <b>REPORTES</b>
                                    </Typography>
                              </Grid>
                        </Grid>
                        <Grid container xs={12} sm={12} md={12} spacing={1}>
                              <ThemeProvider theme={theme}>
                                    <Grid item sm={12} xs={12} md={6}>
                                          <table className="table table-hover table-bordered table-sm ">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="4">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              DATOS
                                                                              DE
                                                                              CLIENTES
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              CLIENTES
                                                                              ACTIVOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    afiliados
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              DESAFILIADOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    desafiliados
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              CLIENTES
                                                                              CON
                                                                              1
                                                                              MASCOTA
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascota1.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              CLIENTES
                                                                              CON
                                                                              2
                                                                              MASCOTAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascota2.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              CLIENTES
                                                                              CON
                                                                              3
                                                                              MASCOTAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascota3.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              CLIENTES
                                                                              CON
                                                                              4
                                                                              MASCOTAS
                                                                              O
                                                                              MÁS
                                                                        </b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascota4.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </Grid>

                                    <Grid item sm={12} xs={12} md={6}>
                                          <table className="table table-hover table-bordered table-sm">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="6">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                              DE
                                                                              LOS
                                                                              COBROS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              ITEM
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              N°
                                                                              COBROS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              VALOR
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              PARA
                                                                              EL
                                                                              PRÓXIMO
                                                                              MES
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b></b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b></b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              CON
                                                                              1
                                                                              MES
                                                                              DE
                                                                              MORA
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>0</b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    0
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              CON
                                                                              2
                                                                              MES
                                                                              DE
                                                                              MORA
                                                                        </b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    noCobrosDos
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    moraDosMes
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              COBROS
                                                                              CON
                                                                              3
                                                                              MES
                                                                              DE
                                                                              MORA
                                                                        </b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    noCobrosTres
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>

                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              {formatterPeso.format(
                                                                                    moraTresMes
                                                                              )}
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                          <table className="table table-hover table-bordered table-sm">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "red",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="4">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              INFORMACIÓN
                                                                              DE
                                                                              MASCOTAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TOTAL
                                                                              MASCOTAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    totalMascotas
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      {mascotasCategoria.map(
                                                            (m) => (
                                                                  <tr>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle2"
                                                                                    component="h2"
                                                                                    align="start"
                                                                              >
                                                                                    <b>
                                                                                          {
                                                                                                m.Tipo
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                        <th
                                                                              colSpan="2"
                                                                              scope="rowgroup"
                                                                        >
                                                                              <Typography
                                                                                    variant="subtitle1"
                                                                                    component="h2"
                                                                                    align="center"
                                                                              >
                                                                                    <b>
                                                                                          {
                                                                                                m.TOTAL
                                                                                          }
                                                                                    </b>
                                                                              </Typography>
                                                                        </th>
                                                                  </tr>
                                                            )
                                                      )}
                                                </tbody>
                                          </table>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                          <table className="table table-hover table-bordered table-sm">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "red",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="6">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              MASCOTAS
                                                                              SEGÚN
                                                                              SU
                                                                              SEXO
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "#ff0000",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              ITEM
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              MACHOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th colSpan="2">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              HEMBRAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              PERROS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    dogsMachos.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    dogsHembras.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              GATOS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    catMachos.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    catHembras.length
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                          <table className="table table-hover table-bordered table-sm">
                                                <thead>
                                                      <tr
                                                            style={{
                                                                  background:
                                                                        "red",
                                                                  color: "white",
                                                            }}
                                                      >
                                                            <th colSpan="4">
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              TAMAÑOS
                                                                              DE
                                                                              LA
                                                                              MASCOTAS
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </thead>

                                                <tbody>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TAMAÑO
                                                                              MINY
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascotaMiny
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>

                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TAMAÑO
                                                                              MAMUT
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascotaMamut
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TAMAÑO
                                                                              TOGO
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascotaTogo
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                      <tr>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle2"
                                                                        component="h2"
                                                                        align="start"
                                                                  >
                                                                        <b>
                                                                              TAMAÑO
                                                                              TOBBY
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                            <th
                                                                  colSpan="2"
                                                                  scope="rowgroup"
                                                            >
                                                                  <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        align="center"
                                                                  >
                                                                        <b>
                                                                              {
                                                                                    mascotasTobby
                                                                              }
                                                                        </b>
                                                                  </Typography>
                                                            </th>
                                                      </tr>
                                                </tbody>
                                          </table>
                                    </Grid>
                              </ThemeProvider>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default Estimaciones;
