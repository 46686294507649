import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { DataGrid /*esES*/ } from "@mui/x-data-grid";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Switch from "@material-ui/core/Switch";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { esES } from "@mui/x-data-grid/locales/esES";
import DateFnsUtils from "@date-io/date-fns";
import {
      MuiPickersUtilsProvider,
      KeyboardDatePicker,
} from "@material-ui/pickers";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import FlagIcon from "@mui/icons-material/Flag";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./footer/Footer";
import { useSelector } from "react-redux";

const ClientesRetirados = () => {
      const token = sessionStorage.getItem("token");
      const navigate = useNavigate();
      const [desafiliados, setDesafiliados] = useState([]);
      const [actualizar, setActualizar] = useState(false);
      const [openState, setOpenState] = useState(false);
      const [openEditar, setOpenEditar] = useState(false);
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [size, setSize] = useState(0);
      const [data, setData] = useState([]);
      const selectedAgencia = useSelector(
            (state) => state.agencia.selectedAgencia
      );

      useEffect(() => {
            if (!token) {
                  navigate("/");
            } else {
                  modificarEstadoPrenecesidad();
                  cambiarEstadoPagos();
                  fetch(
                        `https://dejandohuellas.syfacol.com/contratos/desafiliados/${selectedAgencia}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setDesafiliados(res);
                        });
                  setActualizar(false);
            }
      }, [actualizar, selectedAgencia]);

      const modificarEstadoPrenecesidad = () => {
            //Modifica el estado de los planes PRENECESIDAD
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };

            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/modificarEstado",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const columns = [
            {
                  field: "Codigo",
                  headerName: "No. Contrato",
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.Codigo}</b>
                        </Stack>
                  ),
            },
            {
                  field: "NoContrato",
                  headerName: "No. Contrato",
                  hide: true,
                  width: 120,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Stack direction="row" spacing={1}>
                              <b>{params.row.NoContrato}</b>
                        </Stack>
                  ),
            },

            {
                  field: "Nombres",
                  headerName: "Cliente",
                  width: 280,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Cedula",
                  headerName: "Cédula",
                  width: 110,
                  headerAlign: "center",
            },
            {
                  field: "Sexo",
                  headerName: "Sexo",
                  width: 90,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <strong>{params.row.Sexo}</strong>
                        </div>
                  ),
            },
            {
                  field: "Direccion",
                  headerName: "Dirección",
                  width: 210,
                  headerAlign: "center",
                  editable: true,
            },

            {
                  field: "Telefono",
                  headerName: "Teléfono",
                  width: 110,
                  headerAlign: "center",
            },
            {
                  field: "Estado",
                  headerName: "Estado",
                  width: 150,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <div
                              style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                              }}
                        >
                              <Chip
                                    style={{
                                          fontSize: "14px",
                                          color: "white",
                                          background: `${
                                                (params.row.Estado ===
                                                      "ACTIVO" &&
                                                      "green") ||
                                                (params.row.Estado ===
                                                      "DESAFILIADO" &&
                                                      "#ff0000") ||
                                                "#ff0000"
                                          }`,
                                    }}
                                    label={<b>{params.row.Estado}</b>}
                              />
                        </div>
                  ),
            },
            {
                  field: "Opciones",
                  headerName: "Opciones",
                  width: 160,
                  headerAlign: "center",
                  renderCell: (params) => (
                        <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              container
                              justifyContent="center"
                              alignItems="end"
                        >
                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Estados"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                abrirModalEstados(params.row)
                                          }
                                    >
                                          <FlagIcon />
                                    </IconButton>
                              </Tooltip>

                              <Tooltip
                                    interactive
                                    placement={"top"}
                                    title={"Ver Contrato"}
                              >
                                    <IconButton
                                          aria-label="details"
                                          style={{
                                                color: "#ff0000",
                                          }}
                                          size={"medium"}
                                          onClick={() =>
                                                verContrato(
                                                      params.row.NoContrato
                                                )
                                          }
                                    >
                                          <PictureAsPdfIcon />
                                    </IconButton>
                              </Tooltip>
                        </Grid>
                  ),
            },
      ];

      const cambiarEstadoPagos = () => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
            };
            fetch(
                  "https://dejandohuellas.syfacol.com/contratos/modificarEstadosPagos",
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
      };

      const verContrato = (Cedula) => {
            const ruta = `https://contrato.dejandohuellas.com.co/contrato/${Cedula}`;
            window.open(ruta, "_blank");
            window.focus();
      };

      const handleChange = (e) => {
            filtrarContratos(e.target.value);
      };

      const filtrarContratos = (termino) => {
            if (termino === "") {
                  setActualizar(true);
            } else {
                  fetch(
                        `https://dejandohuellas.syfacol.com/contratos/filtro2/${termino}/${selectedAgencia}`
                  )
                        .then((res) => res.json())
                        .then((res) => {
                              setSize(res.length);
                              setDesafiliados(res);
                        });
            }
      };

      const abrirModalEstados = (props) => {
            setData(props);
            setOpenState(!openState);
      };

      const cerrarModalEstados = () => {
            setOpenState(!openState);
            setData("");
      };

      const modificarEstado = (values) => {
            const requestInit = {
                  method: "PUT",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(values),
            };
            fetch(
                  `https://dejandohuellas.syfacol.com/contratos/contratosEstado/${data.NoContrato}`,
                  requestInit
            )
                  .then((res) => res.text())
                  .then((res) => console.log(res));
            setActualizar(!actualizar);
            setOpenState(!openState);
      };

      return (
            <>
                  <Header />
                  <Container>
                        <Dialog open={openState} onClose={openState}>
                              <div style={{ padding: "15px" }}>
                                    <Formik
                                          initialValues={{
                                                Estado: data.Estado,
                                          }}
                                          validationSchema={Yup.object().shape({
                                                Estado: Yup.string().required(
                                                      "* Requerido"
                                                ),
                                          })}
                                          onSubmit={(values, { resetForm }) => {
                                                modificarEstado(values);
                                                resetForm();
                                          }}
                                    >
                                          {({
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                handleBlur,
                                                handleChange,
                                          }) => (
                                                <Form>
                                                      <DialogTitle>
                                                            Estado del Contrato
                                                      </DialogTitle>

                                                      <DialogContent>
                                                            <FormControl
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  size="small"
                                                            >
                                                                  <Select
                                                                        labelId="demo-simple-select-filled-label"
                                                                        id="demo-simple-select-filled"
                                                                        name="Estado"
                                                                        value={
                                                                              values.Estado
                                                                        }
                                                                        onBlur={
                                                                              handleBlur
                                                                        }
                                                                        onChange={
                                                                              handleChange
                                                                        }
                                                                        error={Boolean(
                                                                              touched.Estado &&
                                                                                    errors.Estado
                                                                        )}
                                                                        helperText={
                                                                              touched.Estado &&
                                                                              errors.Estado
                                                                        }
                                                                  >
                                                                        <MenuItem
                                                                              value={
                                                                                    "ACTIVO"
                                                                              }
                                                                        >
                                                                              ACTIVO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "DESAFILIADO"
                                                                              }
                                                                        >
                                                                              DESAFILIADO
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                              value={
                                                                                    "EN MORA"
                                                                              }
                                                                        >
                                                                              EN
                                                                              MORA
                                                                        </MenuItem>
                                                                  </Select>
                                                            </FormControl>
                                                      </DialogContent>
                                                      <DialogActions>
                                                            <Grid
                                                                  container
                                                                  md={12}
                                                                  justifyContent="center"
                                                            >
                                                                  <Button
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="submit"
                                                                        style={{
                                                                              marginRight:
                                                                                    "10px",
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                  >
                                                                        Modificar
                                                                  </Button>
                                                                  <Button
                                                                        style={{
                                                                              color: "white",
                                                                              background:
                                                                                    "#800000",
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        type="button"
                                                                        onClick={() =>
                                                                              cerrarModalEstados()
                                                                        }
                                                                  >
                                                                        Cancelar
                                                                  </Button>
                                                            </Grid>
                                                      </DialogActions>
                                                </Form>
                                          )}
                                    </Formik>
                              </div>

                              <DialogActions></DialogActions>
                        </Dialog>

                        <Grid
                              container
                              md={12}
                              justifyContent="center"
                              mb={2}
                              style={{ marginTop: "70px" }}
                        >
                              <Typography
                                    variant="h5"
                                    component="h2"
                                    align="center"
                                    style={{ marginTop: "12px" }}
                              >
                                    <b>DESAFILIADOS Y PLANES CANCELADOS</b>
                              </Typography>
                        </Grid>
                        <Grid
                              container
                              justifyContent="center"
                              md={12}
                              style={{ marginBottom: "20px" }}
                        >
                              <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    justifyContent="start"
                              >
                                    <TextField
                                          name="filtro"
                                          label="Buscar..."
                                          size="small"
                                          onChange={handleChange}
                                          variant="standard"
                                          margin="normal"
                                          InputProps={{
                                                endAdornment: (
                                                      <InputAdornment position="start">
                                                            <SearchIcon />
                                                      </InputAdornment>
                                                ),
                                          }}
                                    />
                              </Grid>

                              <Grid item md={12}>
                                    <Typography
                                          variant="subtitle1"
                                          align="start"
                                          style={{
                                                marginBottom: "8px",
                                                marginTop: "12px",
                                          }}
                                    >
                                          <b>
                                                Retirados, Planes Pagos y
                                                Servicios Particulares Pagos
                                          </b>
                                    </Typography>
                              </Grid>

                              <Grid item xs={12} md={12} mt={2}>
                                    <Paper>
                                          <DataGrid
                                                getRowId={(row) =>
                                                      row.NoContrato
                                                }
                                                autoHeight
                                                localeText={
                                                      esES.components
                                                            .MuiDataGrid
                                                            .defaultProps
                                                            .localeText
                                                }
                                                pagination
                                                pageSize={rowsPerPage}
                                                onPageSizeChange={(
                                                      newPageSize
                                                ) =>
                                                      setRowsPerPage(
                                                            newPageSize
                                                      )
                                                }
                                                rowsPerPageOptions={[5, 10, 15]}
                                                labelRowsPerPage={
                                                      "Cargos por pagina"
                                                }
                                                rowCount={size}
                                                page={page}
                                                onPageChange={(newPage) =>
                                                      setPage(newPage)
                                                }
                                                columns={columns}
                                                rows={desafiliados}
                                                //loading
                                          />
                                    </Paper>
                              </Grid>
                        </Grid>
                  </Container>
                  <Footer />
            </>
      );
};

export default ClientesRetirados;
