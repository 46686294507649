import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Importa PersistGate
import store, { persistor } from "./store"; // Importa tu store y persistor
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
      <Provider store={store}>
            {/* Agrega PersistGate alrededor de tu componente App */}
            <PersistGate loading={null} persistor={persistor}>
                  <React.StrictMode>
                        <App />
                  </React.StrictMode>
            </PersistGate>
      </Provider>,
      document.getElementById("root")
);

reportWebVitals();
